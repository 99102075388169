import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Insights from "./pages/Insights";
import Careers from "./pages/Careers";
import About from "./pages/About";
import CloudConsultation from "./pages/CloudConsultation";
import CloudMigration from "./pages/CloudMigration";
import DevOps from "./pages/DevOps";
import Deployment from "./pages/Deployment";
import WellArchitected from "./pages/WellArchitected";
import CostOptimization from "./pages/CostOptimization";
import Bigdata from "./pages/Bigdata";
import Iot from "./pages/Iot";

import Web from "./pages/Web";
import AndroidApp from "./pages/AndroidApp";
import Uiux from "./pages/Uiux";
import AppModernization from "./pages/AppModernization";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/insights" element={<Insights />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/about" element={<About />} />
        <Route path="/cloudConsultation" element={<CloudConsultation />} />
        <Route path="/cloudMigration" element={<CloudMigration />} />
        <Route path="/devOps" element={<DevOps />} />
        <Route path="/deployment" element={<Deployment />} />
        <Route path="/wellArchitected" element={<WellArchitected />} />
        <Route path="/costOptimization" element={<CostOptimization />} />
        <Route path="/bigdata" element={<Bigdata />} />
        <Route path="/iot" element={<Iot />} />
        <Route path="/uiux" element={<Uiux />} />
        <Route path="/web" element={<Web />} />
        <Route path="/android" element={<AndroidApp />} />
        <Route
          path="/applicationmodernization"
          element={<AppModernization />}
        />

        {/* Default route */}
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
