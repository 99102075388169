import React from "react";
// import "../styles/Uiux.css";

import app1 from "../images/devops-1.png";
import app2 from "../images/devops-2.png";
import app3 from "../images/devops-3.png";
import app4 from "../images/devops-4.png";
import app5 from "../images/devops-5.png";

export default function DevOps() {
  return (
    <div className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5 ">
      <h1 style={{ color: "rgb(57,49,133)" }}>Devops</h1>

      {/* \first */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              letterSpacing: 0.5,
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Devops:
            </h2>
            In the dynamic landscape of digital transformation, DevOps stands as
            a critical framework facilitating seamless collaboration between
            development and operations teams, ultimately streamlining the
            delivery of high-quality software applications. In the context of
            mobile app development, the principles and practices of DevOps play
            a pivotal role in ensuring the agility, reliability, and scalability
            of mobile applications.
          </p>
        </div>
        <img src={app1} style={{ width: "30%" }} />
      </div>
      {/* second */}
      <div className=" d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5 m-5 m  ">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <img
            src={app2}
            class="img"
            style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
          />
          <div style={{ flex: 1 }}>
            <h2 style={{ color: "rgb(58 54 187)", marginBottom: "10px" }}>
              Needs of Devops:
            </h2>
            <p
              style={{
                fontSize: "18px",
                wordWrap: "break-word",
                wordBreak: "break-word",
                width: "80%",
                fontFamily: "revert-layer",
              }}
            >
              DevOps encourages the automation of certain parts of the
              development system, which allows development staff to spend more
              time on other tasks. The automation component also helps catch
              potential errors or security threats that could harm the software
              and its efficiency. This helps limit the amount of additional work
              the development and operations teams need to commit to a project.
            </p>
          </div>
        </div>
      </div>
      {/* third */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              letterSpacing: 0.5,
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Why BTL? :
            </h2>
            Our company is committed to implementing DevOps practices for our
            clients because we understand the transformative impact it can have
            on their businesses. By embracing DevOps, we empower our clients to
            streamline their software delivery processes, enabling faster
            time-to-market for their applications while ensuring higher quality
            and reliability. DevOps principles promote collaboration between
            development and operations teams, leading to improved communication,
            efficiency, and agility throughout the development lifecycle.
            Through automation, infrastructure as code, and continuous
            integration/continuous delivery (CI/CD) pipelines, we help our
            clients achieve greater scalability, resilience, and
            cost-effectiveness in managing their IT infrastructure. By adopting
            DevOps, our clients can respond more effectively to market demands,
            innovate faster, and stay ahead of the competition in today's
            dynamic digital landscape.
          </p>
        </div>
        <img src={app3} style={{ width: "30%" }} />
      </div>
      {/* four */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          fontFamily: "revert-layer",
        }}
      >
        <img
          src={app4}
          class="img"
          style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
        />
        <div style={{ flex: 1 }}>
          <h2 style={{ color: "rgb(58 54 187)", marginBottom: "10px" }}>
            Our Work strategies:
          </h2>
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
            }}
          >
            Our approach to DevOps revolves around a strategic fusion of
            cultural, technological, and process-oriented initiatives tailored
            to meet the unique needs of each client. We prioritize fostering a
            collaborative environment where development and operations teams
            work seamlessly together, breaking down silos and promoting shared
            ownership of the software delivery pipeline. Embracing automation
            and infrastructure as code, we optimize workflows to enable
            continuous integration and delivery, facilitating rapid and reliable
            deployment of software updates. Our focus on monitoring, logging,
            and analytics ensures proactive detection and resolution of issues,
            enhancing system reliability and performance. By aligning our DevOps
            strategies with our clients' business objectives, we empower them to
            accelerate innovation, improve operational efficiency, and deliver
            exceptional value to their customers.
          </p>
        </div>
      </div>
      {/* five */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              letterSpacing: 0.5,
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Conclusion:
            </h2>
            In conclusion, our dedication to DevOps is not merely about adopting
            a set of tools or practices; it's about instilling a culture of
            collaboration, innovation, and continuous improvement within our
            organization and for our clients. By leveraging DevOps principles,
            we strive to break down barriers, increase efficiency, and drive
            meaningful outcomes. Through strategic alignment, proactive
            monitoring, and a commitment to excellence, we enable our clients to
            navigate the complexities of modern software development with
            confidence. In doing so, we empower them to achieve their goals,
            stay ahead of the curve, and succeed in today's ever-evolving
            digital landscape.
          </p>
        </div>
        <img src={app5} style={{ width: "30%" }} />
      </div>
    </div>
  );
}
