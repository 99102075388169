import React, { useState, useEffect } from "react";
import contactimage from "../images/contact.jpg";
import Form from "react-bootstrap/Form";
import "../App.css";
// import Select from "react-select";
import Card from "react-bootstrap/Card";
import styled from "styled-components";
import { useForm, ValidationError } from "@formspree/react";
import Thankyou from "../images/thankyou.png";
import ci from "../images/ci.png";

export default function Contact() {
  const parentDivStyle = {
    backgroundImage: `url(${contactimage})`,
    width: "100%",
    // flexWrap: "wrap",
    height: "100%",
  };
  const mobileStyles = `
  @media (max-width:"100%") {
    .parent-div {
      background-position: center;
      background-size: cover;
    }
  }
`;
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [showForm1, setShowForm1] = useState(false);
  const [showForm2, setShowForm2] = useState(true);
  const [showForm3, setShowForm3] = useState(false);

  // const handleSubmit = () => {
  //   const subject = subject; // Set the subject value here
  //   const name = name; // Set the name value here
  //   const email = email; // Set the email value here
  //   const message = message; // Set the message value here

  //   const mailtoLink = `mailto:dhaneswarsetha.123@gmail.com?subject=${encodeURIComponent(
  //     subject
  //   )}&body=${encodeURIComponent(
  //     `Name: ${name}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`
  //   )}`;

  //   window.location.href = mailtoLink;
  // };
  const handleToggle1 = () => {
    setShowForm1(!showForm1);
    setShowForm2(false);
    setShowForm3(false);
  };

  const handleToggle2 = () => {
    setShowForm1(false);
    setShowForm2(!showForm2);
    setShowForm3(false);
  };

  const handleToggle3 = () => {
    setShowForm1(false);
    setShowForm2(false);
    setShowForm3(!showForm3);
  };
  const Form1 = () => {
    const [state, handleSubmit] = useForm("xbjnqyke");
    if (state.succeeded) {
      return (
        <>
          <img src={Thankyou} style={{ width: "100%" }} />
          {/* <p>Thanks for joining!</p>; */}
        </>
      );
    }
    return (
      <form onSubmit={handleSubmit}>
        <Container>
          <HiddenText>
            <p>Or sign in manually:</p>
          </HiddenText>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
            }}
          >
            <Input type="text" name="name" placeholder="Your Name" required />
            <ValidationError prefix="Name" field="name" errors={state.errors} />
            <Select name="service" required>
              <option value="">Select Service</option>
              <option value="Cloud Consultation">Cloud Consultation</option>
              <option value="Cloud Migration">Cloud Migration</option>
              <option value="Cost Optimization">Cost Optimization</option>
              <option value="DevOps">DevOps</option>
              <option value="Deployment">Deployment</option>
              <option value="Well-Architected Reviews">
                Well-Architected Reviews
              </option>
            </Select>
            <ValidationError
              prefix="Service"
              field="service"
              errors={state.errors}
            />
            <Input type="email" name="email" placeholder="Email" required />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
            <Input
              type="phonenumber"
              name="phonenumber"
              placeholder="Phone Number"
              required
            />
            <ValidationError
              prefix="Phonenumber"
              field="phonenumber"
              errors={state.errors}
            />
            <TextArea name="message" placeholder="Message" rows="4" required />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />
          </div>
          <SubmitButton
            type="submit"
            value="Submit"
            disabled={state.submitting}
          />
        </Container>
      </form>
    );
  };

  const Form2 = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [country, setCountry] = useState(null);
    const [phone, setPhone] = useState("");
    const [resume, setResume] = useState("");
    const [message, setMessage] = useState("");
    const [countries, setCountries] = useState([]);
    console.log("====================================", countries);
    console.log();
    console.log("====================================");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      // Fetch country data from the API
      fetch("https://restcountries.com/v3.1/all")
        .then((response) => response.json())
        .then((data) => {
          setCountries(data);
          setLoading(false);
        })
        .catch((error) => {
          console.log("Error fetching country data:", error);
          setLoading(false);
        });
    }, []);
    const handleCountryChange = (selectedCountry) => {
      setCountry(selectedCountry);
    };
    const [state, handleSubmit] = useForm("mzbnyvyn");
    if (state.succeeded) {
      return (
        <>
          <img src={Thankyou} style={{ width: "100%" }} />
          {/* <p>Thanks for joining!</p>; */}
        </>
      );
    }
    return (
      <div>
        <form onSubmit={handleSubmit} enctype="multipart/form-data">
          <Container>
            <HiddenText>
              <p>Or sign in manually:</p>
            </HiddenText>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              <Input
                style={{ paddingRight: "10px" }}
                type="text"
                name="username"
                placeholder="Firstname"
                required
              />
              <ValidationError
                prefix="Username"
                field="username"
                errors={state.errors}
              />
              <Input
                style={{ paddingRight: "10px" }}
                type="text"
                name="username"
                placeholder="Lastname"
                required
              />

              <ValidationError
                prefix="Username"
                field="username"
                errors={state.errors}
              />
              <Input
                style={{ paddingRight: "10px" }}
                type="email"
                name="username"
                placeholder="Email"
                required
              />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
              {loading ? (
                <p>Loading countries...</p>
              ) : (
                <Select name="country" required>
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option
                      key={country.name.common}
                      value={country.name.common}
                    >
                      {country.name.common}
                    </option>
                  ))}
                </Select>
              )}
              <ValidationError
                prefix="Country"
                field="country"
                errors={state.errors}
              />
              <Input
                style={{ paddingRight: "10px" }}
                type="text"
                name="phonenumber"
                placeholder="PhoneNumber"
                required
              />
              <ValidationError
                prefix="Phonenumber"
                field="phonenumber"
                errors={state.errors}
              />
              <Input
                type="text"
                name="cv"
                placeholder="your cv drive link"
                required
                style={{ paddingRight: "10px" }}
              />
              <ValidationError prefix="cv" field="cv" errors={state.errors} />
              <TextArea
                // style={{ paddingRight: "10px" }}
                name="message"
                placeholder="Message"
                rows="4"
                required
              />
              <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
              />
            </div>
            <SubmitButton
              type="submit"
              value="Submit"
              disabled={state.submitting}
            />
          </Container>
        </form>
      </div>
    );
  };
  const Form3 = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [country, setCountry] = useState(null);
    const [phone, setPhone] = useState("");
    const [resume, setResume] = useState("");
    const [message, setMessage] = useState("");
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      // Fetch country data from the API
      fetch("https://restcountries.com/v3.1/all")
        .then((response) => response.json())
        .then((data) => {
          setCountries(data);
          setLoading(false);
        })
        .catch((error) => {
          console.log("Error fetching country data:", error);
          setLoading(false);
        });
    }, []);
    const handleCountryChange = (selectedCountry) => {
      setCountry(selectedCountry);
    };
    const [state, handleSubmit] = useForm("mdoqydby");
    if (state.succeeded) {
      return (
        <>
          <img src={Thankyou} style={{ width: "100%" }} />
          {/* <p>Thanks for joining!</p>; */}
        </>
      );
    }
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <Container>
            <HiddenText>
              <p>Or sign in manually:</p>
            </HiddenText>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              <Input
                style={{ paddingRight: "10px" }}
                type="text"
                name="firstname"
                placeholder="Firstname"
                required
              />
              <ValidationError
                prefix="firstname"
                field="firstname"
                errors={state.errors}
              />
              <Input
                style={{ paddingRight: "10px" }}
                type="text"
                name="lastname"
                placeholder="Lastname"
                required
              />

              <ValidationError
                prefix="lastname"
                field="lastname"
                errors={state.errors}
              />
              <Input
                style={{ paddingRight: "10px" }}
                type="email"
                name="username"
                placeholder="Email"
                required
              />
              <ValidationError
                prefix="email"
                field="email"
                errors={state.errors}
              />
              {loading ? (
                <p>Loading countries...</p>
              ) : (
                <Select name="country" required>
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option
                      key={country.name.common}
                      value={country.name.common}
                    >
                      {country.name.common}
                    </option>
                  ))}
                </Select>
              )}
              <ValidationError
                prefix="country"
                field="country"
                errors={state.errors}
              />
              <TextArea
                // style={{ paddingRight: "10px" }}
                name="message"
                placeholder="Message"
                rows="4"
                required
              />
              <ValidationError
                prefix="message"
                field="message"
                errors={state.errors}
              />
            </div>
            <SubmitButton
              type="submit"
              value="Submit"
              disabled={state.submitting}
            />
          </Container>
        </form>
      </div>
    );
  };
  return (
    <div>
      <div class="style-0">
        <div class="style-1">
          <div class="style-2">
            <div class="style-3">
              <div class="style-4">
                <div class="style-5">
                  <h1 class="style-6">
                    How Can We Help <span class="style-7">You?</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="style-9" className=" m-7 col-15 p-5 ">
        <h2 class="style-10">Get in touch</h2>
        <div style={{ display: "flex" }}>
          <p class="style-11" style={{ fontSize: "20px" }}>
            Feel free to reach out to us using the form below, or via
            email/phone. Our dedicated team is here to assist you with any
            inquiries or requests you may have!
          </p>
          <img src={ci} style={{ width: "30%" }} />
        </div>
      </div>
      {/* <div class="style-9">
        <h2 class="style-10">Get in touch</h2>
        <p class="style-11">
          Please fill out the form below. You can also contact us via
          email/phone, and our team will gladly handle your request!
        </p>
      </div> */}
      <div
        class="style-9"
        className="  col-10 p-5 "
        style={{
          marginTop: "-5%",
        }}
      >
        <div
          className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5 col-45"
          id="contactForm"
          style={{
            marginTop: "-5%",
          }}
        >
          <div
            className="part-container"
            style={{
              marginTop: "-15%",
            }}
          >
            <div className="container">
              <div className="button-cont">
                <button
                  className={showForm2 ? "active" : ""}
                  onClick={handleToggle2}
                >
                  Join our Team
                </button>
                <button
                  className={showForm1 ? "active" : ""}
                  onClick={handleToggle1}
                >
                  Request for Services
                </button>

                <button
                  className={showForm3 ? "active" : ""}
                  onClick={handleToggle3}
                >
                  Other Queries
                </button>
              </div>
              <div className={`form-containers ${showForm1 ? "show" : ""}`}>
                <Form1 />
              </div>
              <div className={`form-containers ${showForm2 ? "show" : ""}`}>
                <Form2 />
              </div>
              <div className={`form-containers ${showForm3 ? "show" : ""}`}>
                <Form3 />
              </div>
            </div>
          </div>
          <div
            className="part-container"
            style={{
              marginLeft: "50px",
              // paddingBottom: "10px",
              // paddingTop: "10px",
            }}
          >
            <Card style={{ width: "100%", border: "2px solid" }}>
              <Card.Body>
                <Card.Title>
                  <h2
                    style={{
                      width: "250px",
                      display: "flex",
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    Contact Information
                  </h2>
                </Card.Title>
                {/* <Card.Subtitle className="m text-muted">
                  <h4>
                    {" "}
                    B-1805, Dosti Oak, Dosti West County, Balkum Road,
                    Thane West, MH-400608
                  </h4>
                </Card.Subtitle> */}
                <Card.Text>View Map</Card.Text>
                <Card.Text>
                  Phone: +91-8260226919<br></br>
                  Email: sales@bhashasoftware.com
                  <hr></hr>
                </Card.Text>
                {/* <Card.Subtitle className="mb-2 text-muted">
        Regional Headquarters
      </Card.Subtitle> */}
                <Card.Text>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.2579660123974!2d72.99098607520915!3d19.227585982008474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bfa2eba605e5%3A0xd4b375147f568f59!2sDosti%20Oak!5e0!3m2!1sen!2sin!4v1713262876759!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    // style="border:0;"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const HiddenText = styled.div`
  display: none;
  text-align: center;
  margin-bottom: 6px;

  @media screen and (max-width: 650px) {
    display: block;
  }
`;

const Input = styled.input`
  width: 50%;
  padding: 12px;
  border: solid 0.5px;
  border-color: rgb(9, 7, 71) 47%;
  border-radius: 4px;
  margin: 5px 0;
  opacity: 0.85;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;

  &:hover {
    opacity: 1;
  }
`;

const SubmitButton = styled.input`
  width: 50%;
  padding: 12px;
  border: solid;
  border-color: rgb(9, 7, 71) 47%;

  border-radius: 4px;
  margin: 5px 0;
  opacity: 0.85;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
  background: linear-gradient(
    109deg,
    rgba(19, 19, 49, 1) 17%,
    rgba(9, 7, 71, 1) 47%,
    rgba(19, 16, 113, 1) 65%,
    rgba(42, 89, 168, 1) 93%
  );
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;
const TextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  border: solid 0.5px;
  border-color: rgb(9, 7, 71) 47%;

  border-radius: 4px;
  margin: 5px 0;
  opacity: 0.85;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;

  &:hover {
    opacity: 1;
  }
`;

const Select = styled.select`
  width: 50%;
  padding: 12px;
  border: solid 0.5px;
  border-color: rgb(9, 7, 71) 47%;

  border-radius: 4px;
  margin: 5px 0;
  opacity: 0.85;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
  borderColor:#000000;
  borderWidth:2px

  &:hover {
    opacity: 1;
  }
`;
