import React from "react";
// import "../styles/Uiux.css";

import app1 from "../images/arc-1.png";
import app2 from "../images/arc-3.png";
import app3 from "../images/arc-2.png";
import app4 from "../images/arc-4.png";
import app5 from "../images/arc-5.png";

export default function WellArchitected() {
  return (
    <div className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5 ">
      <h1 style={{ color: "rgb(57,49,133)" }}>WellArchitected Reviews</h1>

      {/* \first */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              textAlign: "justify",
              margin: "0 auto",
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              WellArchitected:
            </h2>
            In today's rapidly evolving digital landscape, conducting
            Well-Architected Reviews in AWS is akin to harnessing the
            behind-the-scenes magic that ensures optimal performance and
            reliability for your cloud infrastructure. These reviews are
            essential for guaranteeing that the AWS solutions you implement
            operate seamlessly and remain resilient under varying workloads and
            conditions. Whether you're architecting a simple application or a
            complex enterprise system, Well-Architected Reviews provide
            invaluable insights and recommendations to optimize efficiency,
            enhance security, and maximize cost-effectiveness. By adhering to
            Well-Architected principles and best practices, you can fortify your
            AWS environment, ensuring it scales effortlessly, maintains high
            availability, and aligns with your business objectives for sustained
            success in the cloud.
          </p>
        </div>
        <img src={app1} style={{ width: "30%" }} />
      </div>
      {/* second */}
      <div className=" d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5 m-5 m  ">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <img
            src={app2}
            class="img"
            style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
          />
          <div style={{ flex: 1 }}>
            <h2
              style={{
                color: "rgb(58 54 187)",
                marginBottom: "10px",
                marginLeft: "10%",
              }}
            >
              Needs of WellArchitected:
            </h2>
            <p
              style={{
                fontSize: "18px",
                wordWrap: "break-word",
                wordBreak: "break-word",
                width: "80%",
                fontFamily: "revert-layer",
                textAlign: "justify",
                margin: "0 auto",
              }}
            >
              Well-Architected Reviews in AWS serve as a strategic compass for
              optimizing your cloud architecture. By evaluating your workload
              against AWS's five pillars—operational excellence, security,
              reliability, performance efficiency, and cost optimization—these
              reviews offer actionable insights to enhance your cloud
              infrastructure's resilience, security posture, and
              cost-effectiveness. Leveraging Well-Architected Reviews empowers
              you to identify potential bottlenecks, streamline operations, and
              ensure your AWS environment is aligned with industry best
              practices. Whether you're fine-tuning existing deployments or
              embarking on new cloud initiatives, Well-Architected Reviews
              provide a roadmap for building robust, scalable, and
              cost-efficient solutions on AWS.
            </p>
          </div>
        </div>
      </div>
      {/* third */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              textAlign: "justify",
              margin: "0 auto",
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Why BTL? :
            </h2>
            Choosing our company for Well-Architected Reviews in AWS ensures you
            benefit from a wealth of expertise and experience in cloud
            architecture and optimization. Our team comprises seasoned
            AWS-certified professionals who possess a deep understanding of AWS
            services, best practices, and industry standards. We take a tailored
            approach to each review, meticulously assessing your architecture
            against the five pillars of the Well-Architected Framework to
            provide actionable recommendations customized to your unique
            business needs. With a proven track record of delivering
            high-quality solutions and driving tangible results for our clients,
            entrusting us with your Well-Architected Reviews guarantees a
            thorough, insightful analysis of your AWS environment and a roadmap
            for maximizing its performance, security, and cost-effectiveness.
          </p>
        </div>
        <img src={app3} style={{ width: "30%" }} />
      </div>
      {/* four */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          fontFamily: "revert-layer",
        }}
      >
        <img
          src={app4}
          class="img"
          style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
        />
        <div style={{ flex: 1 }}>
          <h2 style={{ color: "rgb(58 54 187)", marginBottom: "10px" }}>
            Our Work strategies:
          </h2>
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
            }}
          >
            Our work strategies for Well-Architected Reviews in AWS are built
            upon a foundation of thoroughness, collaboration, and innovation. We
            begin by conducting a comprehensive assessment of your AWS
            environment, meticulously analyzing each component against the
            Well-Architected Framework's five pillars. This involves close
            collaboration with your team to understand your business objectives,
            technical requirements, and operational constraints. Once we've
            gathered insights, we leverage our expertise to identify areas of
            improvement and develop tailored recommendations to optimize your
            AWS architecture. Our approach emphasizes practicality and
            feasibility, ensuring that our suggestions align with your
            organization's goals while addressing any existing challenges.
            Throughout the review process, we prioritize clear communication and
            transparency, keeping you informed at every step and fostering a
            collaborative environment for decision-making. Additionally, we stay
            abreast of the latest AWS innovations and best practices,
            integrating cutting-edge solutions into our recommendations to
            future-proof your architecture and maximize its potential. By
            partnering with us for Well-Architected Reviews in AWS, you can
            expect a strategic, data-driven approach that drives continuous
            improvement, enhances operational efficiency, and unlocks new
            opportunities for innovation in the cloud.
          </p>
        </div>
      </div>
      {/* five */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              textAlign: "justify",
              margin: "0 auto",
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Conclusion:
            </h2>
            In conclusion, Well-Architected Reviews in AWS represent a pivotal
            opportunity to optimize your cloud infrastructure for peak
            performance, security, and cost-effectiveness. By partnering with
            our company, you gain access to a team of seasoned AWS professionals
            dedicated to conducting thorough assessments and providing tailored
            recommendations aligned with your business objectives. Our work
            strategies prioritize collaboration, innovation, and practicality,
            ensuring that our recommendations are not only insightful but also
            actionable. Through clear communication and transparent
            decision-making processes, we empower you to make informed choices
            that drive continuous improvement and innovation in your AWS
            environment. By entrusting us with your Well-Architected Reviews,
            you can rest assured that your AWS architecture is in capable hands,
            poised for enhanced resilience, efficiency, and scalability.
            Together, we can unlock the full potential of your cloud
            infrastructure and propel your organization towards sustained
            success in the digital age.
          </p>
        </div>
        <img src={app5} style={{ width: "30%" }} />
      </div>
    </div>
  );
}
