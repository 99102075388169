import React, { useState } from "react";
// import Header from "../component/Header";
import Carousel from "react-bootstrap/Carousel";

import cloud from "../images/cloud.png";
import rating from "../images/rating.png";
import help from "../images/help.png";
import "../App.css";

import slider1 from "../images/banner0.1.jpg";
import slider2 from "../images/banner0.2.jpeg";
import slider5 from "../images/banner0.5.jpeg";
import slider3 from "../images/banner0.4.jpg";
import Card from "react-bootstrap/Card";
import pyt from "../images/cloudoptimisation.png";
import sql from "../images/cloudcontrol.png";
import Html from "../images/cloudDeployment.png";
import typesc from "../images/bigdata1.png";
import cloudmigration from "../images/cloudmigration.png";
import docker from "../images/wellarc.png";
import data from "../images/iot.png";
import first from "../images/aws.c3.png";
import seconds from "../images/reactNative.png";

import five from "../images/next.4ea9e125.png";
import six from "../images/mongodb.c74798c9.png";
import react from "../images/react.a9a2a79d.png";
import azure from "../images/azure.png";
import gcp from "../images/gcp.jpg";

import nine from "../images/typescript.039575ca.png";
import devopsicon from "../images/devopsicon.png";
import cloudcompu from "../images/cloudcompu.jpg";
import jenkins from "../images/jenkins.jpeg";
import Ansible from "../images/ansible.png";
import kubernet from "../images/kubernet.png";
import tera from "../images/tera.png";
import dockerss from "../images/docker.png";
import Maven from "../images/maven.png";

import fourteen from "../images/node.cbf3cf0a.png";
import fifteen from "../images/angular.48ca9a9a.png";
import seventeen from "../images/php.12a702c2.png";
import nighteen from "../images/figma.a4e94d31.png";
import twenty from "../images/git.da6ae57d.png";
import twentyfour from "../images/nginx.7da4c143.png";
import devops from "../images/devops.jpg";
import photo1 from "../images/AWS-Logo-PNG-Images.png";
import strategy from "../images/strategy.png";
import modelmigration from "../images/model-of-migration.png";
import Amazon from "../images/Amazon-Web-Services.jpg";
import datamigration from "../images/datamigration.jpeg";
import Security from "../images/security.jpg";
import testing from "../images/testing.jpg";
import Monitoring from "../images/monitoring.jpg";
import recovery from "../images/recovery.jpg";

function Home() {
  const slides = document.querySelectorAll(".slide");
  const dots = document.querySelectorAll(".dot");
  const [index, setIndex] = useState(0);

  const prevSlide = (n) => {
    setIndex((prevIndex) => prevIndex + n);
    changeSlide();
  };

  const nextSlide = (n) => {
    setIndex((prevIndex) => prevIndex + n);
    changeSlide();
  };

  const changeSlide = () => {
    if (index > slides.length - 1) {
      setIndex(0);
    }

    if (index < 0) {
      setIndex(slides.length - 1);
    }

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = i === index ? "block" : "none";
      dots[i].classList.toggle("active", i === index);
    }
  };

  return (
    <div className=" hideScrollbar">
      {/* Slider */}
      <div>
        <Carousel data-bs-theme="dark">
          <Carousel.Item>
            <img
              className="d-block w-100 h-50"
              style={{ maxHeight: "550px" }}
              //   style={{ height: "600px" }}
              src={slider1}
              alt="First slide"
            />
            {/* <Carousel.Caption>
              <div style={card}>
                <h1 class="hero-content">
                  Stellantis Partners with GlobalLogic to open a Software
                  Facility in Poland<span></span>
                </h1>
                <p class="hero-description">
                  Look beyond traditional outsourcing models, assess product,
                  process, and partnership maturity while leveraging innovation
                  labs and best practices.
                </p>
                <a
                  style={StyledAnchor}
                  href="https://www.globallogic.com/about/news/stellantis-partners-with-gl-to-open-a-software-facility-in-poland/"
                  target="_blank"
                  tabindex="0"
                  style={a}
                >
                  Read More ><i class="fa fa-angle-right hero-arrow"></i>
                </a>
              </div>
            </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 h-70"
              style={{ maxHeight: "550px" }}
              src={slider2}
              alt="Second slide"
            />
            {/* <Carousel.Caption>
              <div style={card}>
                <h1 class="hero-content">
                  Stellantis Partners with GlobalLogic to open a Software
                  Facility in Poland<span></span>
                </h1>
                <p class="hero-description">
                  Look beyond traditional outsourcing models, assess product,
                  process, and partnership maturity while leveraging innovation
                  labs and best practices.
                </p>
                <a
                  style={StyledAnchor}
                  href="https://www.globallogic.com/about/news/stellantis-partners-with-gl-to-open-a-software-facility-in-poland/"
                  target="_blank"
                  tabindex="0"
                  style={a}
                >
                  Read More ><i class="fa fa-angle-right hero-arrow"></i>
                </a>
              </div>
            </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 h-50"
              style={{ maxHeight: "550px" }}
              src={slider5}
              alt="Third slide"
            />

            {/* <Carousel.Caption>
              <div style={card}>
                <h1 class="hero-content">
                  Stellantis Partners with GlobalLogic to open a Software
                  Facility in Poland<span></span>
                </h1>
                <p class="hero-description">
                  Look beyond traditional outsourcing models, assess product,
                  process, and partnership maturity while leveraging innovation
                  labs and best practices.
                </p>
                <a
                  style={StyledAnchor}
                  href="https://www.globallogic.com/about/news/stellantis-partners-with-gl-to-open-a-software-facility-in-poland/"
                  target="_blank"
                  tabindex="0"
                  style={a}
                >
                  Read More ><i class="fa fa-angle-right hero-arrow"></i>
                </a>
              </div>
            </Carousel.Caption> */}
          </Carousel.Item>
        </Carousel>
      </div>

      {/* Second Phase */}
      <div className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5 ">
        <h1>
          {" "}
          <h1>Technology We Specialize In</h1>
        </h1>

        <div className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5">
          {/* <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <img src={third} alt="1st" />
          </div> */}
          <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <img src={first} alt="1st" />
          </div>
          <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <span
              style={{
                display: "block",
                marginLeft: "30%",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  width: "50%",
                  // height: "120px",
                  background: "white",
                  marginTop: "-5%",
                }}
                src={azure}
                alt="1st"
              />

              <h4
                style={{
                  fontWeight: "bold",
                  paddingTop: "20px",
                }}
              >
                Azure
              </h4>
            </span>
          </div>
          <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <img
              src={gcp}
              style={{
                width: "180px",
                height: "120px",
                background: "white",
                marginTop: "-5%",
              }}
              alt="1st"
            />
          </div>
          <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <img
              src={devopsicon}
              style={{
                width: "180px",
                height: "120px",
                background: "white",
                marginTop: "-5%",
              }}
              alt="1st"
            />
          </div>
          <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <img src={seconds} alt="1st" />
          </div>
          <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <img src={nine} alt="1st" />
          </div>
          <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <img src={five} alt="1st" />
          </div>
          <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <img src={react} alt="1st" />
          </div>
          <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <img src={six} alt="1st" />
          </div>

          <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <img src={fifteen} alt="1st" />
          </div>

          <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <img src={fourteen} alt="1st" />
          </div>
          <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <img src={seventeen} alt="1st" />
          </div>

          {/* 3rd------------------------------------------- */}

          {/* <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <img src={nighteen} alt="1st" />
          </div> */}
          <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <img src={twenty} alt="1st" />
          </div>
          <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <img src={twentyfour} alt="1st" />
          </div>
          <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <img
              style={{
                width: "120px",
                height: "120px",
                background: "white",
                marginTop: "-5%",
              }}
              src={cloudcompu}
              alt="1st"
            />
          </div>
          <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <img
              style={{
                width: "150px",
                height: "120px",
                background: "white",
                marginTop: "-5%",
              }}
              src={jenkins}
              alt="1st"
            />
          </div>
          <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <img
              style={{
                width: "150px",
                height: "120px",
                background: "white",
                marginTop: "-5%",
              }}
              src={Ansible}
              alt="1st"
            />
          </div>
          <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <img
              style={{
                width: "150px",
                height: "100px",
                background: "white",
                marginTop: "-5%",
              }}
              src={kubernet}
              alt="1st"
            />
          </div>
          <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <img
              style={{
                width: "180px",
                height: "150px",
                background: "white",
                marginTop: "-5%",
              }}
              src={dockerss}
              alt="1st"
            />
          </div>
          <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <img
              style={{
                width: "180px",
                height: "150px",
                background: "white",
                marginTop: "-5%",
              }}
              src={tera}
              alt="1st"
            />
          </div>
          <div
            className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5"
            style={{
              width: 200,
              height: "200",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <span
              style={{
                display: "block",
                marginLeft: "30%",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  width: "50%",
                  // height: "120px",
                  background: "white",
                  marginTop: "-5%",
                }}
                src={Maven}
                alt="1st"
              />

              <h4
                style={{
                  fontWeight: "bold",
                  paddingTop: "20px",
                }}
              >
                Maven
              </h4>
            </span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <img
            src={photo1}
            class="img"
            style={{ maxWidth: "100%", height: "auto", marginRight: "20px" }}
          />
          <div style={{ flex: 1 }}>
            <h4 style={{ color: "rgb(58 54 187)", marginBottom: "10px" }}>
              Why choose AWS as your cloud partner?
            </h4>
            <p
              style={{
                fontSize: "16px",
                wordWrap: "break-word",
                wordBreak: "break-word",
                width: "80%",
              }}
            >
              Every functionality in cloud available with 165 fully featured
              services Largest community of customers and partners Most flexible
              and secure cloud computing environment available today Fastest
              pace of innovation, releasing 1,957 new services and features in
              2018 Most proven operational expertise of over 12 years to
              millions of customers around the world
            </p>
          </div>
        </div>
        {/* <div style={industriesImagesStyle}>
          <div style={industryStyle}>
            <img src={java} alt="Java" style={industryImageStyle} />
            <img src={python} alt="python" style={industryImageStyle} />
            <img src={react} alt="react" style={industryImageStyle} />
            <img src={java} alt="java" style={industryImageStyle} />

            
          </div>
          <div style={industryStyle}>
            <img src={java} alt="Java" style={industryImageStyle} />
            <img src={python} alt="python" style={industryImageStyle} />
            <img src={react} alt="react" style={industryImageStyle} />
            <img src={java} alt="java" style={industryImageStyle} />

            
          </div>
        </div> */}
      </div>

      {/* third phase */}
      {/* <div className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5">
        <h1>We’ve Helped These Brands Shape Their Business & Future</h1>
        <div style={text}>
          <Carousel data-bs-theme="dark" style={second}>
            <Carousel.Item>
              <img style={banner} src={page1} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img style={banner} src={page2} alt="Second slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img style={banner} src={page3} alt="Third slide" />
            </Carousel.Item>
          </Carousel>
        </div>
      </div> */}

      {/* we can help you section */}
      {/* <div className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5">
        <h1>We Can Help You</h1>
        <div style={{ display: "flex" }}>
          <di>
            <ul className="wrapper">
              <li className="mui">
                <img className="image" src={py} alt="" />
              </li>
              <br />
              <br />
              <li className="mui">
                <img className="image" src={react} alt="" />
              </li>
              <li className="mui">
                <img className="image" src={javap} alt="" />
              </li>
              <br />
              <br />
              <li className="mui">
                <img className="image" src={reacts} alt="" />
              </li>
              <li className="mui">
                <img className="image" src={java} alt="" />
              </li>
              <br />
              <br />
              <li className="mui">
                <img className="image" src={php} alt="" />
              </li>
            </ul>
          </di>
        </div>
      </div> */}
      <div className="d-flex flex-wrap align-items-center justify-content-center ">
        <h1>
          {" "}
          <h1>We Can Help You</h1>
        </h1>
        <div
          style={{ display: "flex", flexWrap: "wrap" }}
          className="d-flex flex-wrap align-items-center justify-content-center "
        >
          <div
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5"
          >
            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src={cloudmigration} />
              <Card.Body>
                <Card.Title>Cloud Migration</Card.Title>
                <Card.Text>
                  Cloud migration is the strategic process of shifting an
                  organization's digital assets, including data, applications,
                  and services, from on-premises infrastructure to cloud-based
                  platforms. This transition optimizes scalability, reliability,
                  and cost-effectiveness, harnessing the advantages of cloud
                  computing like elasticity and flexibility to drive enhanced
                  agility, and competitiveness in the digital landscape.
                </Card.Text>
                <a href="cloudMigration">
                  <button class="button-85" role="button">
                    Learn More
                  </button>
                </a>
              </Card.Body>
            </Card>
          </div>
          <div
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5"
          >
            <Card style={{ width: "18rem" }}>
              <Card.Img
                variant="top"
                src={sql}
                style={{ width: "100%", height: "182px" }}
              />
              <Card.Body>
                <Card.Title>Cloud Consultation</Card.Title>
                <Card.Text>
                  Cloud consultation provides tailored advice on maximizing
                  cloud computing benefits for organizational objectives.
                  Consultants assess current infrastructure, propose migration
                  strategies, and offer recommendations for security and cost
                  optimization. This expertise empowers businesses to leverage
                  cloud technology effectively, enhancing efficiency and
                  innovation capabilities.
                  {/* Cloud consultation offers personalized guidance on leveraging cloud computing to meet organizational objectives. Consultants assess current infrastructure, devise migration strategies, and provide recommendations for security and cost optimization. This expertise enables businesses to harness cloud technology effectively, enhancing efficiency and fostering innovation */}
                </Card.Text>

                <a href="cloudConsultation" style={{ textDecoration: "none" }}>
                  {" "}
                  <button class="button-85" role="button">
                    Learn More
                  </button>
                </a>
              </Card.Body>
            </Card>{" "}
          </div>
          <div
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5"
          >
            <Card style={{ width: "18rem" }}>
              <Card.Img
                variant="top"
                src={pyt}
                style={{ width: "100%", height: "182px" }}
              />
              <Card.Body>
                <Card.Title>Cost Optimization</Card.Title>
                <Card.Text>
                  Cost optimization involves identifying and implementing
                  strategies to minimize expenses while maximizing value in
                  business operations. It encompasses analyzing spending
                  patterns, optimizing resource allocation, leveraging
                  cost-effective technologies, and streamlining processes to
                  achieve optimal efficiency and financial sustainability within
                  an organization.
                </Card.Text>
                <a href="costOptimization" style={{ textDecoration: "none" }}>
                  {" "}
                  <button class="button-85" role="button">
                    Learn More
                  </button>
                </a>
              </Card.Body>
            </Card>{" "}
          </div>
          <div
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5"
          >
            <Card style={{ width: "18rem" }}>
              <Card.Img
                variant="top"
                src={Html}
                style={{ width: "100%", height: "182px" }}
              />
              <Card.Body>
                <Card.Title>Cloud Deployment</Card.Title>
                <Card.Text>
                  Cloud deployment involves the configuration and launching of
                  applications, services, or infrastructure on cloud computing
                  platforms. This process includes setting up virtual machines,
                  databases, and networking components to enable efficient
                  resource allocation and scalability. Cloud deployment enhances
                  accessibility and flexibility, facilitating the delivery of
                  digital solutions for organizations.
                </Card.Text>
                <a href="deployment" style={{ textDecoration: "none" }}>
                  {" "}
                  <button class="button-85" role="button">
                    Learn More
                  </button>
                </a>
              </Card.Body>
            </Card>{" "}
          </div>
          <div
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5"
          >
            <Card style={{ width: "18rem" }}>
              <Card.Img
                variant="top"
                src={devops}
                style={{ width: "100%", height: "162px" }}
              />
              <Card.Body>
                <Card.Title>DevOps</Card.Title>
                <Card.Text>
                  DevOps services streamline software development by integrating
                  development and operations teams. Emphasizing collaboration,
                  automation, and CI/CD practices, they accelerate development
                  cycles, enhance deployment frequency, and improve software
                  quality and reliability, fostering innovation and
                  competitiveness in the digital realm.
                </Card.Text>
                <a href="devOps" style={{ textDecoration: "none" }}>
                  {" "}
                  <button class="button-85" role="button">
                    Learn More
                  </button>
                </a>
              </Card.Body>
            </Card>{" "}
          </div>

          <div
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5"
          >
            <Card style={{ width: "18rem" }}>
              <Card.Img
                variant="top"
                src={typesc}
                style={{ width: "100%", height: "162px" }}
              />
              <Card.Body>
                <Card.Title>Big Data Ai</Card.Title>
                <Card.Text>
                  Big Data AI services utilize advanced analytics and machine
                  learning algorithms to extract valuable insights from vast
                  datasets. These services empower organizations to uncover
                  patterns, trends, and correlations, enabling data-driven
                  decision-making and innovation across industries such as
                  healthcare, finance, marketing, and beyond, fostering
                  competitive advantage and growth.
                </Card.Text>
                <a href="bigdata" style={{ textDecoration: "none" }}>
                  {" "}
                  <button class="button-85" role="button">
                    Learn More
                  </button>
                </a>
              </Card.Body>
            </Card>
            <div
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5"
            >
              <Card style={{ width: "18rem" }}>
                <Card.Img
                  variant="top"
                  src={data}
                  style={{ width: "100%", height: "142px" }}
                />
                <Card.Body>
                  <Card.Title>Block Chain,IOT</Card.Title>
                  <Card.Text>
                    Blockchain and IoT services integrate blockchain technology
                    with Internet of Things (IoT) devices to ensure secure,
                    transparent, and efficient data transactions and
                    communication. This integration enables decentralized data
                    management, secure transactions, and automated processes,
                    driving innovation and trust in industries such as supply
                    chain, healthcare, and logistics.
                  </Card.Text>

                  <a href="iot">
                    <button class="button-85" role="button">
                      Learn More
                    </button>
                  </a>
                </Card.Body>
              </Card>{" "}
            </div>{" "}
            <div
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5"
            >
              <Card style={{ width: "18rem" }}>
                <Card.Img
                  variant="top"
                  src={docker}
                  style={{ width: "100%", height: "162px" }}
                />
                <Card.Body>
                  <Card.Title>Architected</Card.Title>
                  <Card.Text>
                    Cloud Architected Services provide customized solutions for
                    designing, implementing, and optimizing cloud-based
                    architectures. Utilizing best practices and industry
                    standards, these services enable organizations to build
                    scalable, reliable, and cost-effective cloud
                    infrastructures, optimizing performance, security, and
                    efficiency to meet business needs and objectives.
                  </Card.Text>
                  <a href="wellArchitected">
                    <button class="button-85" role="button">
                      Learn More
                    </button>
                  </a>
                </Card.Body>
              </Card>{" "}
            </div>
          </div>
        </div>
      </div>
      {/* Examples of our Expertise */}
      {/* <div
        className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5"
        style={{ marginTop: "150px" }}
      >
        <h1>Examples of our Expertise</h1>
        <div style={{ display: "flex" }}>
          <di>
            <ul className="wrapper">
              <li className="mui">
                <img className="image" src={py} alt="" />
              </li>
              <br />
              <br />
              <li className="mui">
                <img className="image" src={py} alt="" />
              </li>
              <li className="mui">
                <img className="image" src={javap} alt="" />
              </li>
              <br />
              <br />
              <li className="mui">
                <img className="image" src={reacts} alt="" />
              </li>
              <li className="mui">
                <img className="image" src={javap} alt="" />
              </li>
              <br />
              <br />
              <li className="mui">
                <img className="image" src={py} alt="" />
              </li>
            </ul>
          </di>
        </div>
      </div> */}
      <div className="d-flex flex-wrap align-items-center justify-content-center ">
        <h1>Key Areas of Expertise</h1>
        <div
          style={{ display: "flex", flexWrap: "wrap" }}
          className="d-flex flex-wrap align-items-center justify-content-center "
        >
          <div
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5"
          >
            <Card style={{ width: "18rem" }}>
              <Card.Img
                variant="top"
                style={{ height: "150px" }}
                src={strategy}
              />
              <Card.Body>
                <Card.Title>Strategy and Planning</Card.Title>
                <Card.Text>
                  Our expert team begins every migration project with a
                  comprehensive assessment of the client’s existing
                  infrastructure. We collaborate closely with our clients to
                  create a tailored cloud migration strategy that aligns with
                  their specific business goals. This includes cost analysis,
                  risk assessment, and a detailed roadmap for the entire
                  migration process, ensuring success and satisfaction.
                </Card.Text>
                <h5 class="hue">
                  <a href="contact">Contact Us</a>
                </h5>
              </Card.Body>
            </Card>
          </div>
          <div
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5"
          >
            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src={modelmigration} />
              <Card.Body>
                <Card.Title>Migration of Diverse Workloads</Card.Title>
                <Card.Text>
                  Our accomplished team has adeptly migrated diverse workloads
                  to AWS, spanning virtualized environments, databases, legacy
                  applications, and beyond. With extensive expertise, we
                  navigate complex migration scenarios, guaranteeing operational
                  continuity while delivering seamless transitions tailored to
                  our clients' needs.
                </Card.Text>
                <h5 class="hue">
                  <a href="contact">Contact Us</a>
                </h5>
              </Card.Body>
            </Card>{" "}
          </div>
          <div
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5"
          >
            <Card style={{ width: "18rem" }}>
              <Card.Img
                variant="top"
                src={Amazon}
                style={{ width: "100%", height: "163px" }}
              />
              <Card.Body>
                <Card.Title> AWS Services Selection</Card.Title>
                <Card.Text>
                  With a relentless commitment to client success, our team
                  excels in meticulously selecting the most suitable AWS
                  services for each workload. Leveraging our expertise, we make
                  strategic decisions based on factors such as scalability,
                  redundancy, security, and compliance. This ensures that
                  clients fully optimize the benefits of AWS, driving efficiency
                  and innovation across their operations.
                </Card.Text>
                <h5 class="hue">
                  <a href="contact">Contact Us</a>
                </h5>
              </Card.Body>
            </Card>{" "}
          </div>
          <div
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5"
          >
            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src={datamigration} />
              <Card.Body>
                <Card.Title>Data Migration and Integration</Card.Title>
                <Card.Text>
                  Migrating data is pivotal in any migration endeavor. With our
                  expertise, we seamlessly transfer, transform, and integrate
                  data into AWS. Employing diverse methods such as database
                  replication, data lakes, and real-time synchronization, we
                  ensure a smooth transition. Our meticulous approach guarantees
                  data integrity and accessibility, crucial for the success of
                  the migration project.
                </Card.Text>
                <h5 class="hue">
                  <a href="contact">Contact Us</a>
                </h5>
              </Card.Body>
            </Card>{" "}
          </div>
          <div
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5"
          >
            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src={Security} />
              <Card.Body>
                <Card.Title>Security and Compliance</Card.Title>
                <Card.Text>
                  Security is a paramount concern for our clients, and we take
                  it seriously. We ensure that the design of the AWS cloud
                  architectures with a strong focus on security, employing best
                  practices, encryption, and identity management. We also ensure
                  that the migration process complies with industry-specific
                  regulations and standards.
                </Card.Text>
                <h5 class="hue">
                  <a href="contact">Contact Us</a>
                </h5>
              </Card.Body>
            </Card>{" "}
          </div>
          <div
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5"
          >
            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src={testing} />
              <Card.Body>
                <Card.Title>Testing and Validation</Card.Title>
                <Card.Text>
                  Throughout the migration process, rigorous testing is
                  paramount. We meticulously conduct performance assessments,
                  data integrity checks, and failover testing at every stage.
                  This meticulous approach ensures a seamless transition to AWS,
                  safeguarding against disruptions and guaranteeing optimal
                  performance for our clients.
                </Card.Text>
                <h5 class="hue">
                  <a href="contact">Contact Us</a>
                </h5>
              </Card.Body>
            </Card>
            <div
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5"
            >
              <Card style={{ width: "18rem" }}>
                <Card.Img
                  variant="top"
                  style={{ height: "170px" }}
                  src={Monitoring}
                />
                <Card.Body>
                  <Card.Title>Monitoring and Optimization</Card.Title>
                  <Card.Text>
                    Post-migration, our services extend to ongoing monitoring
                    and optimization for the AWS environment. Proactively
                    addressing performance issues, we ensure efficiency and
                    cost-effectiveness. Our commitment to resource optimization
                    aligns with evolving needs, ensuring sustained success.
                  </Card.Text>
                  <h5 class="hue">
                    <a href="contact">Contact Us</a>
                  </h5>
                </Card.Body>
              </Card>{" "}
            </div>{" "}
            <div
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5"
            >
              <Card style={{ width: "18rem" }}>
                <Card.Img
                  variant="top"
                  style={{ height: "170px" }}
                  src={recovery}
                />
                <Card.Body>
                  <Card.Title>Disaster Recovery</Card.Title>
                  <Card.Text>
                    PSSPL implements robust disaster recovery and backup
                    solutions for AWS, safeguarding critical data and
                    applications. This includes automated backup routines and
                    comprehensive recovery plans, ensuring resilience against
                    unforeseen events and minimizing downtime for our clients'
                    operations.
                  </Card.Text>
                  <h5 class="hue">
                    <a href="contact">Contact Us</a>
                  </h5>
                </Card.Body>
              </Card>{" "}
            </div>
          </div>
        </div>
      </div>

      {/* <div style={{ display: "flex" }}> */}
      <div className="d-flex flex-wrap align-items-center justify-content-center ">
        <h1>
          {" "}
          Why Work with Us What Sets <b>BTL</b> apart!
        </h1>
      </div>

      <section className="main-container">
        <div className="main">
          <div className="big-circle">
            <div className="icon-block">
              <img
                src="https://cloudworkmates.com/wp-content/uploads/2020/05/AWS96.png"
                alt="web design icon"
              />
            </div>
            <div className="icon-block">
              <img src={help} alt="game design icon" />
            </div>
            <div className="icon-block">
              <img src={rating} alt="game dev icon" />
            </div>
            <div className="icon-block">
              <img src={cloud} alt="ui-ux icon" />
            </div>
          </div>
          <div className="circle">
            <div className="icon-block">
              <img
                src="https://www.yudiz.com/codepen/animated-portfolio/app-icon.png"
                alt="app icon"
              />
            </div>
            <div className="icon-block">
              <img
                src="https://www.yudiz.com/codepen/animated-portfolio/blockchain-icon.png"
                alt="blockchain icon"
              />
            </div>
            <div className="icon-block">
              <img
                src="https://www.yudiz.com/codepen/animated-portfolio/arvr-icon.png"
                alt="ar-vr icon"
              />
            </div>
            <div className="icon-block">
              <img
                src="https://www.yudiz.com/codepen/animated-portfolio/artificial-intelligence-icon.png"
                alt="artificial intelligence icon"
              />
            </div>
          </div>
          <div className="center-logo">
            <img
              src="https://www.yudiz.com/codepen/animated-portfolio/logo.png"
              alt="logo"
            />
          </div>
        </div>
      </section>
      <div className="paragraph-container">
        <p className="paragraph">
          We have a growing team of engineers. Our entire team is accredited on
          the products and services we deliver and specifically certified on the
          AWS platform. We are supported by veterans of the industry who have
          come together to offer the best of breed support and solutions for our
          customers.
        </p>
      </div>

      {/* footer header */}
      <div
        className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5"
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          marginTop: "150px",
          paddingBottom: "50px",
          gap: "10px",
        }}
      >
        <h1>Contact Us</h1>
        <button className="bu">
          <a href="contact">Future Client</a>
        </button>
        <button className="bu">
          <a href="contact">Future Employee</a>
        </button>
      </div>
      {/* footer section */}
    </div>
  );
}

export default Home;

// Inline Styles
const heroContainerStyle = {
  backgroundColor: "hwb(0 93% 5% / 0.437)",
  height: "250px",
  width: "100%",
  top: "50px",
};

const heroContentStyle = {
  color: "#f37037",
  fontSize: "20px",
};

const heroDescriptionStyle = {
  color: "#f37037",
  fontSize: "20px",
};

const heroLinkStyle = {
  color: "#f37037",
  textDecoration: "none",
  display: "block",
  cursor: "pointer",
  fontSize: "20px",
};

const industriesContainerStyle = {
  display: "flex",
  justifyContent: "space-around",
  flexWrap: "wrap",
  padding: "50px",
  marginTop: "70px",
};

const industriesImagesStyle = {
  display: "block",
  justifyContent: "space-evenly",
  flexWrap: "wrap",
};

const industryStyle = {
  display: "flex",
  justifyContent: "space-evenly",
  flexWrap: "wrap",
};

const industryImageStyle = {
  width: "100px",
  height: "100px",
  justifyContent: "space-arround",

  // paddingLeft: "50px",
  paddingRight: "40px",
  paddingBottom: "40px",
  // paddingTop: "40px",
};
const banner = {
  height: "90%",
  width: "100%",
};
const text = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // height: "100vh",
};
const second = {
  width: "70%",
  // height: "100px",
  alignSelf: "center",
  justifyContent: "center",
};
const card = {
  backgroundColor: "hwb(0 93% 5% / 0.437)",
  //   width: "250px",
  height: "250px",
  width: "100%",
  top: "50px",
  justifyContent: "center",
  flexWrap: "wrap",
};
const a = {
  color: "#f37037",
  textDecoration: "none",
  display: "block",
  cursor: "pointer",
  fontSize: "20px",
};
const h3 = {
  color: "white",
};
const StyledAnchor = {
  textDecoration: "none",
};
