import React from "react";
// import "../styles/Uiux.css";

import app1 from "../images/cc-1.png";
import app2 from "../images/cc-2.png";
import app3 from "../images/cc-3.png";
import app4 from "../images/cc-4.png";
import app5 from "../images/cc-5.png";

export default function CloudConsultation() {
  return (
    <div className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5 ">
      <h1 style={{ color: "rgb(57,49,133)" }}>CloudConsultation</h1>

      {/* \first */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              textAlign: "justify",
              margin: "0 auto",
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              CloudConsultation:
            </h2>
            Cloud consultation refers to the practice of providing expert
            advice, guidance, and support to organizations regarding their cloud
            computing strategies, architectures, and implementations. As
            businesses increasingly migrate their operations to the cloud, the
            need for specialized knowledge and insight into cloud technologies
            grows. Cloud consultation services encompass a range of activities,
            including assessing the suitability of cloud solutions for specific
            business needs, designing cloud architectures that optimize
            performance and cost-efficiency, and providing ongoing support to
            ensure smooth operation and security. Cloud consultants leverage
            their expertise in areas such as infrastructure as a service (IaaS),
            platform as a service (PaaS), and software as a service (SaaS) to
            help clients harness the full potential of cloud computing for their
            businesses.
          </p>
        </div>
        <img src={app1} style={{ width: "30%" }} />
      </div>
      {/* second */}
      <div className=" d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5 m-5 m  ">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <img
            src={app2}
            class="img"
            style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
          />
          <div style={{ flex: 1 }}>
            <h2 style={{ color: "rgb(58 54 187)", marginBottom: "10px" }}>
              Needs of CloudConsultation:
            </h2>
            <p
              style={{
                fontSize: "18px",
                wordWrap: "break-word",
                wordBreak: "break-word",
                width: "80%",
                fontFamily: "revert-layer",
              }}
            >
              Cloud consultation plays a pivotal role in guiding businesses
              through the intricacies of cloud computing, offering tailored
              solutions to meet their specific needs. From strategic planning
              and architecture design to cost optimization and security,
              consultants provide invaluable expertise to optimize performance
              and efficiency while ensuring compliance and resilience. By
              partnering with cloud consultants, organizations can navigate the
              complexities of the cloud with confidence, driving innovation and
              growth in the digital era.
            </p>
          </div>
        </div>
      </div>
      {/* third */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              textAlign: "justify",
              margin: "0 auto",
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Why BTL? :
            </h2>
            At BTL, our Cloud Consultation service is your compass in the
            ever-expanding cloud landscape. Whether you're just dipping your
            toes into cloud technology or looking to optimize your existing
            setup, our expert consultants provide tailored guidance to navigate
            the complexities and harness the full potential of cloud solutions.
            We assess your unique needs, design a strategic roadmap, and offer
            insights on best practices, cost optimization, and security. With
            BTL's Cloud Consultation, you gain clarity and confidence to make
            informed decisions, ensuring your cloud journey is smooth,
            efficient, and aligned with your business objectives.
          </p>
        </div>
        <img src={app3} style={{ width: "30%" }} />
      </div>
      {/* four */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          fontFamily: "revert-layer",
        }}
      >
        <img
          src={app4}
          class="img"
          style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
        />
        <div style={{ flex: 1 }}>
          <h2 style={{ color: "rgb(58 54 187)", marginBottom: "10px" }}>
            Our Work strategies:
          </h2>
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
            }}
          >
            At BTL, our Cloud Consultation strategies are designed to guide
            businesses through every stage of their cloud journey with precision
            and expertise. We begin by conducting a thorough assessment of your
            current infrastructure and business objectives, allowing us to
            tailor a strategic roadmap that aligns with your unique needs. This
            roadmap outlines clear steps for cloud adoption, migration, and
            optimization, ensuring that your transition to the cloud is seamless
            and efficient. Throughout the process, our team provides hands-on
            support, from implementation to ongoing management, to ensure that
            you fully leverage the benefits of cloud technology. With BTL's
            Cloud Consultation services, you can confidently navigate the
            complexities of the cloud landscape and unlock new opportunities for
            innovation and growth.
          </p>
        </div>
      </div>
      {/* five */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              textAlign: "justify",
              margin: "0 auto",
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Conclusion:
            </h2>
            BTL's Cloud Consultation services offer a comprehensive and tailored
            approach to help businesses harness the full potential of cloud
            technology. By conducting thorough assessments, developing strategic
            roadmaps, and providing hands-on support, we empower organizations
            to navigate the complexities of the cloud landscape with confidence
            and clarity. Whether you're looking to adopt cloud solutions,
            optimize existing infrastructure, or enhance cloud security, our
            team of experts is dedicated to guiding you every step of the way.
            Trust BTL to be your partner in unlocking the transformative power
            of the cloud and driving sustainable growth for your business.
          </p>
        </div>
        <img src={app5} style={{ width: "30%" }} />
      </div>
    </div>
  );
}
