import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "../styles/Nav.css";
import React, { useState } from "react";
import Logo from "../images/logo.png";

function Header() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdowns, setShowDropdowns] = useState(false);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };
  const handleDropdownToggles = () => {
    setShowDropdowns(!showDropdowns);
  };
  return (
    <Navbar
      expand="xl"
      className="sticky-top"
      // className="bg-body-tertiary"
      // id="navbar"
      style={{
        background:
          "linear-gradient(109deg, rgba(19,19,49,1) 17%, rgba(9,7,71,1) 47%, rgba(19,16,113,1) 65%, rgba(42,89,168,1)93%)",
        // backgroundColor: "#414042",
        // background: ""
      }}
    >
      <Container>
        <Navbar.Brand href="/">
          <img
            src={Logo}
            style={{
              width: "100px",
              height: "100px",
              // marginLeft: "120px",
              border: "none",
            }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          style={{ justifyContent: "space-evenly" }}
        >
          <Nav
            className="me-auto"
            style={{ padding: "10px", marginLeft: "60px" }}
          >
            <Nav.Link
              className="hover"
              id="basic-nav-dropdown"
              href="home"
              style={{ color: "white" }}
            >
              HOME
            </Nav.Link>
            <Nav.Link
              className="hover"
              id="basic-nav-dropdown"
              href="about"
              style={{ color: "white" }}
            >
              ABOUT US
            </Nav.Link>
            <NavDropdown
              className="hover"
              title="CLOUD SERVICES"
              id="basic-nav-dropdown"
              onMouseEnter={handleDropdownToggle}
              onMouseLeave={handleDropdownToggle}
              show={showDropdown}
              style={{ color: "white" }}
            >
              <NavDropdown.Item href="cloudconsultation" className="hover">
                Cloud Consultation
              </NavDropdown.Item>
              <NavDropdown.Item href="cloudmigration" className="hover">
                Cloud Migration
              </NavDropdown.Item>
              <NavDropdown.Item href="costoptimization" className="hover">
                Cost Optimization
              </NavDropdown.Item>

              {/* <NavDropdown.Item href="deployment" className="hover">
                Deployment
              </NavDropdown.Item> */}
              <NavDropdown.Item href="wellarchitected" className="hover">
                Well-Architected Reviews
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              className="hover"
              title="WEB SERVICES"
              id="basic-nav-dropdown"
              onMouseEnter={handleDropdownToggles}
              onMouseLeave={handleDropdownToggles}
              show={showDropdowns}
              style={{ color: "white" }}
            >
              <NavDropdown.Item href="uiux" className="hover">
                UI/UX Design
              </NavDropdown.Item>
              <NavDropdown.Item href="web" className="hover">
                Web Developement
              </NavDropdown.Item>
              <NavDropdown.Item href="android" className="hover">
                Android Application Development
              </NavDropdown.Item>
              <NavDropdown.Item href="devops" className="hover">
                DevOps Automation
              </NavDropdown.Item>
              <NavDropdown.Item
                href="applicationmodernization"
                className="hover"
              >
                Application Modernization
              </NavDropdown.Item>{" "}
              <NavDropdown.Item href="deployment" className="hover">
                Web-Hosting
              </NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link
              className="hover"
              href="insights"
              id="basic-nav-dropdown"
              style={{ color: "white" }}
            >
              BLOGS
            </Nav.Link> */}
            {/* <Nav.Link
              className="hover"
              id="basic-nav-dropdown"
              href="careers"
              style={{ color: "white" }}
            >
              CAREERS
            </Nav.Link> */}
            {/* <NavDropdown
              className="hover"
              title="ABOUT"
              id="basic-nav-dropdown"
              onMouseEnter={handleDropdownToggles}
              onMouseLeave={handleDropdownToggles}
              show={showDropdowns}
              style={{ color: "white" }}
            >
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
            <Nav.Link
              className="hover"
              href="contact"
              id="basic-nav-dropdown"
              style={{ color: "white", paddingRight: "20px" }}
            >
              CONTACT US
            </Nav.Link>
            {/* <Nav.Link
              href="tel:+918917376596"
              className="hover"
              style={{ color: "white" }}
            >
              <i className="fa fa-phone" aria-hidden="true"></i>+91-77158 74212
            </Nav.Link> */}
            {/* <Nav.Link href="tel:+918917376596" style={{ color: "white" }}>
              <i className="fa fa-phone" aria-hidden="true"></i>+91-8917376596
            </Nav.Link> */}

            {/* <Nav.Link
              className="hover"
              href="contact"
              id="basic-nav-dropdown"
              style={{ color: "white", paddingRight: "20px" }}
            >
              <i class="fas fa-envelope" aria-hidden="true"></i>
              sales@bhashasoftware.com
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>

        {/* <Nav.Link
          className="hover"
          href="mailto:sales@bhashasoftware.com"
          style={{ color: "white" }}
        >
          <i className="fas fa-envelope" aria-hidden="true"></i>
          <span> sales@bhashasoftware.com</span>
        </Nav.Link> */}
      </Container>
    </Navbar>
  );
}

export default Header;
