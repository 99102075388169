import React from "react";
// import "../styles/Uiux.css";

import Web1 from "../images/Web-1.png";
import Web2 from "../images/Web-2.png";
import Web3 from "../images/Web-3.png";
import Web4 from "../images/Web-4.png";
import Web5 from "../images/Web-5.png";

export default function Web() {
  return (
    <div className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5 ">
      <h1>
        {" "}
        <h1 style={{ color: "rgb(57,49,133)" }}>Web Development</h1>
      </h1>
      {/* \first */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              textAlign: "justify",
              margin: "0 auto",
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Web Development:
            </h2>
            Web Development widely known as website development refers to work
            associated with creating and developing a website for the internet.
            Website Development includes web design, web content development,
            web programming, and database management etc. Web development
            services make a website stand out from the competition.
          </p>
        </div>
        <img src={Web1} style={{ width: "30%" }} />
      </div>
      {/* second */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <img
          src={Web2}
          class="img"
          style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
        />
        <div style={{ flex: 1 }}>
          <h2 style={{ color: "rgb(58 54 187)", marginBottom: "10px" }}>
            Needs of Web Development :
          </h2>
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
            }}
          >
            In this digital world a strong online presence is vital for
            businesses of all sizes. It doesn't matter whether you are a start
            up business or a well established organization having an efficient
            and user-friendly website is essential to connect with your audience
            and accomplish success in the competitive online landscape.
          </p>
        </div>
      </div>
      {/* third */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              textAlign: "justify",
              margin: "0 auto",
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Why BTL? :
            </h2>
            <b>BTL</b>a dynamic web development service provider that is
            revolutionizing the way businesses approach their online platforms.
            Our Mission is to provide clients with the service they desire on
            time, under budget and above expectations. We have a team of fully
            passionate people and we use the technology that will be suitable
            for clients.
          </p>
        </div>
        <img src={Web3} style={{ width: "30%" }} />
      </div>
      {/* four */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <img
          src={Web4}
          class="img"
          style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
        />
        <div style={{ flex: 1 }}>
          <h2 style={{ color: "rgb(58 54 187)", marginBottom: "10px" }}>
            Our Work strategies:
          </h2>
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
            }}
          >
            At <b>BTL</b> we understand a website is more than just a group of
            pages, it is a display window that represents your values. Our team
            of experienced web developers, designers and strategists work
            collaboratively to create an outstanding website that not only
            attracts users but also converts them into loyal customers. Our
            energetic development process ensures that we deliver projects on
            time and within budget without compromising on quality.
          </p>
        </div>
      </div>
      {/* five */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              textAlign: "justify",
              margin: "0 auto",
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Conclusion:
            </h2>
            In conclusion, our UI/UX service providing company is dedicated to
            creating seamless, visually captivating, and user-centric designs.
            With a focus on innovation, data-driven insights, and collaboration,
            we deliver exceptional user experiences that elevate businesses to
            new heights. Trust in our expertise and proven track record to
            transform your digital presence and drive customer engagement.
            Partner with us to unleash the true potential of your products and
            services in the dynamic and competitive digital landscape.
          </p>
        </div>
        <img src={Web5} style={{ width: "30%" }} />
      </div>
    </div>
  );
}
