import React from "react";
// import "../styles/Uiux.css";

import app1 from "../images/cloud1.png";
import app2 from "../images/cloud-1.png";
import app3 from "../images/cloud-2.png";
import app4 from "../images/cloud-3.png";
import app5 from "../images/cloud-4.png";

export default function CostOptimization() {
  return (
    <div className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5 ">
      <h1 style={{ color: "rgb(57,49,133)" }}>CostOptimization</h1>

      {/* \first */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              textAlign: "justify",
              margin: "0 auto",
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              CostOptimization:
            </h2>
            Welcome to the era of intelligent cloud cost optimization, where
            every dollar spent delivers maximum value. Our tailored strategies
            delve deep into your architecture, identifying cost-saving
            opportunities at every turn. With a focus on scalability,
            efficiency, and performance, we empower your cloud journey to
            achieve optimal outcomes without breaking the bank. Let's navigate
            the cloud landscape together, optimizing costs while unleashing the
            full power of your digital transformation
          </p>
        </div>
        <img src={app1} style={{ width: "30%" }} />
      </div>
      {/* second */}
      <div className=" d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5 m-5 m  ">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <img
            src={app2}
            class="img"
            style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
          />
          <div style={{ flex: 1 }}>
            <h2 style={{ color: "rgb(58 54 187)", marginBottom: "10px" }}>
              Needs of CostOptimization:
            </h2>
            <p
              style={{
                fontSize: "18px",
                wordWrap: "break-word",
                wordBreak: "break-word",
                width: "80%",
                fontFamily: "revert-layer",
              }}
            >
              Cloud cost optimization meets the pressing needs of modern
              businesses by providing:
              <ol>
                <li>
                  Financial Efficiency: Streamlining cloud expenses to align
                  with budgetary constraints and financial goals.
                </li>
                <li>
                  Scalability: Ensuring resources can seamlessly scale up or
                  down based on demand, minimizing unnecessary spending on idle
                  capacity.
                </li>
                <li>
                  Performance Optimization: Balancing cost savings with
                  performance enhancements to deliver superior user experiences.
                </li>
                <li>
                  Resource Utilization: Maximizing the utilization of cloud
                  resources to minimize waste and optimize ROI.
                </li>
                <li>
                  Strategic Planning: Developing cost optimization strategies
                  tailored to specific business objectives and operational
                  requirements.
                </li>
                <li>
                  Transparency and Accountability: Providing visibility into
                  cloud spending patterns and accountability measures to control
                  costs effectively.
                </li>
                <li>
                  Innovation Enablement: Freeing up financial resources for
                  innovation and strategic initiatives by optimizing cloud
                  expenditures.
                </li>
                <li>
                  Risk Mitigation: Identifying and mitigating potential risks
                  associated with cloud spending, ensuring financial stability
                  and sustainability.
                </li>
                <li>
                  Compliance and Governance: Ensuring adherence to regulatory
                  compliance and governance requirements while optimizing cloud
                  costs.
                </li>
                <li>
                  Continuous Improvement: Implementing ongoing monitoring,
                  analysis, and refinement of cost optimization strategies to
                  adapt to evolving business needs and cloud services.
                </li>
              </ol>
            </p>
          </div>
        </div>
      </div>
      {/* third */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              textAlign: "justify",
              margin: "0 auto",
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Why BTL? :
            </h2>
            BTL stands out as the ideal partner for cost optimization due to our
            tailored approach and proven expertise. We understand that efficient
            resource allocation is critical for business success. Our team
            meticulously analyzes your infrastructure, identifying areas where
            costs can be reduced without sacrificing performance or reliability.
            By leveraging industry-leading tools and best practices, we optimize
            your cloud environment to align with your budgetary constraints and
            long-term goals. With BTL, you can trust that every optimization
            strategy is carefully tailored to your unique needs, ensuring
            maximum cost savings and ROI. Let us help you streamline your
            operations, minimize expenses, and drive sustainable growth in
            today's competitive landscape.
          </p>
        </div>
        <img src={app3} style={{ width: "30%" }} />
      </div>
      {/* four */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          fontFamily: "revert-layer",
        }}
      >
        <img
          src={app4}
          class="img"
          style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
        />
        <div style={{ flex: 1 }}>
          <h2 style={{ color: "rgb(58 54 187)", marginBottom: "10px" }}>
            Our Work strategies:
          </h2>
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
            }}
          >
            Our cloud cost optimization strategy revolves around maximizing
            value while minimizing expenditure. We employ a proactive approach,
            continuously monitoring usage patterns and refining our
            infrastructure to eliminate waste. By optimizing resource
            utilization, implementing robust governance frameworks, and
            leveraging automation, we ensure that every dollar spent on the
            cloud delivers tangible value. Our strategy is not just about
            cutting costs; it's about aligning our cloud investments with
            business objectives, fostering innovation, and driving sustainable
            growth.
          </p>
        </div>
      </div>
      {/* five */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              textAlign: "justify",
              margin: "0 auto",
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Conclusion:
            </h2>
            In conclusion, our approach to cloud cost optimization is driven by
            a commitment to efficiency, value, and innovation. By closely
            monitoring usage, optimizing resources, and implementing governance
            structures, we ensure that our cloud investments deliver maximum
            return on investment. Through automation and continuous refinement,
            we adapt to evolving needs while maintaining a lean and
            cost-effective infrastructure. Ultimately, our strategy enables us
            to strike the perfect balance between cost optimization and
            performance, empowering our organization to thrive in today's
            dynamic digital landscape.
          </p>
        </div>
        <img src={app5} style={{ width: "30%" }} />
      </div>
    </div>
  );
}
