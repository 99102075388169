import React from "react";
import "../styles/About.css";
import photo1 from "../images/photo-1.jpg";
import photo2 from "../images/photo-2.jpg";
import photo3 from "../images/bhashatechnology_logo.png";

import photo4 from "../images/linkedin.png";
import photo5 from "../images/facebook.png";
import photo6 from "../images/instagram.png";
import photo7 from "../images/medium.png";
import photo8 from "../images/photo-6.jpg";
import photo9 from "../images/photo-5.jpg";
import photo10 from "../images/photo-4.jpg";
import APPROACH from "../images/Approach.jpg";
import Vision from "../images/vision.jpg";
import mision from "../images/mission.jpg";

import Video from "../images/video.mp4";

export default function About() {
  return (
    <div>
      <section className="banner">
        <div className="container">
          <h1 className="h1">About us</h1>
          {/* <p className="p">
            Consectetur adipisicing elituscipit quidem facere itaque molestias
            vitae.
          </p> */}
        </div>{" "}
        {/* .container */}
      </section>{" "}
      {/* .banner */}
      <section className="second" style={{ background: "white" }}>
        <div className="container">
          <p className="p">
            <b>BTL</b> is a cloud managed services company focused on AWS
            services, the fastest growing AWS Select Partner in India. We focus
            on Managed services, Cloud Migration and Implementation of various
            value-added services on the cloud. Our skills cut across various
            workloads like Cloud Modernisation, Media Solutions, E-commerce,
            Analytics, IOT, Machine Learning, VR, AR etc. Our Vibrant team of
            100% certiﬁed resources brings the edge to customers for an
            End-to-End AWS partner, committed towards quality and supporting
            their business on a 24X7 basis. Along with the Cloud Services, we
            are also supporting customers in designing their Front-end and
            Backend framework in various platforms We have skilled resources
            with React Native Framework, .Net, Java etc.
          </p>
          <div className="right-img">
            <img
              src={photo3}
              alt="photo"
              className="img"
              style={{
                width: "2500px",
                height: "auto",
                borderRadius: "10px",
              }}
            />
          </div>
        </div>
      </section>
      <section className="third" style={{ background: "white" }}>
        <div className="container">
          <div className="left-content">
            <h2 className="h2">OUR APPROACH</h2>
            <p className="p">
              For successful execution of any project, setting a clear goal is a
              must! And that’s exactly how we start, too. Hence, ‘What do you
              want to achieve?’ is our first question to our client. Then, we
              define the clear and crisp goal. We make it clear, measurable, and
              achievable.
            </p>
          </div>
          <div className="right-img">
            <img src={APPROACH} alt="photo" className="img" />
          </div>
        </div>{" "}
        {/* .container */}
      </section>
      <section className="second" style={{ background: "white" }}>
        <div className="container">
          <div className="left-img">
            <img
              src={Vision}
              alt="Person"
              style={{
                width: "500px",
                height: "auto",
                borderRadius: "10px",
              }}
            />
          </div>
          <div className="right-content" style={{ background: "white" }}>
            <h2 className="h2" style={{ paddingLeft: "40px" }}>
              Our Vision
            </h2>
            <p className="p" style={{ paddingLeft: "40px" }}>
              To be considered the best IT solutions and services company by our
              customers, partners and employees. We strive to achieve this
              through innovation, quality and commitment to first class service
            </p>
            {/* <a class="a" className="cta" href="index.html">
              Learn more »
            </a> */}
          </div>
        </div>
      </section>
      <section className="third" style={{ background: "white" }}>
        <div className="container">
          <div className="left-content">
            <h2 className="h2">Why Choose Us</h2>
            <p className="p">
              When you partner with <b>BTL </b>, you're not just getting a
              product—you're gaining a trusted advisor and a team of experts
              dedicated to your success. From our user-friendly interfaces to
              our personalized support, we go above and beyond to ensure that
              our clients have everything they need to thrive in the cloud.
            </p>

            <a class="a" className="cta" href="contact">
              Contact us »
            </a>
          </div>
          <div className="right-img">
            <img src={photo2} alt="photo" className="img" />
          </div>
        </div>{" "}
        {/* .container */}
      </section>{" "}
      {/* .third */}
      <section className="four" style={{ background: "white" }}>
        {/* <h1
          style={{
            textAlign: "center",
            paddingBottom: "5%",
            fontFamily: "fantasy",
          }}
        >
          MEET THE TEAM
        </h1> */}
        {/* <div className="container">
          <div className="member">
            <img src={photo3} alt="photo" className="img" />
            <h3 className="h3">Margaret Hilda</h3>
            <p className="p">
              Sitamet ipsum dolor consectetur adipisicing elit. Similique
              numquam aperiam commodi! Id, ipsam pariatur! Ex cupiditate
              officiis iste laboriosam quibusdam esse illo praesentium, nemo
              necessitatibus tempore.
            </p>
            <div className="social">
              <a class="a" title="LinkedIn" href="index.html">
                <img src={photo4} alt="linkedin" className="img" />
              </a>
              <a class="a" title="Facebook" href="index.html">
                <img src={photo5} alt="facebook" className="img" />
              </a>
              <a class="a" title="Instagram" href="index.html">
                <img src={photo6} alt="instagram" className="img" />
              </a>
            </div>
          </div>{" "}
         
          <div className="member">
            <img src={photo10} alt="photo" className="img" />
            <h3>Justin Trudeau</h3>
            <p className="p">
              Lpsum dolor sit amet consectetur adipisicing elit. Similique
              numquam aperiam commodi! Id, ipsam pariatur! Ex cupiditate
              officiis iste laboriosam quibusdam esse illo praesentium.
            </p>
            <div className="social">
              <a class="a" title="LinkedIn" href="index.html">
                <img src={photo4} alt="linkedin" className="img" />
              </a>
              <a class="a" title="Instagram" href="index.html">
                <img src={photo6} alt="instagram" className="img" />
              </a>
              <a class="a" title="Medium" href="index.html">
                <img src={photo7} alt="medium" className="img" />
              </a>
            </div>{" "}
         
          </div>{" "}
          
          <div className="member">
            <img src={photo9} alt="photo" className="img" />
            <h3 className="h3">Yoweri Museveni</h3>
            <p className="p">
              Consectetur upsum dolor sit amet adipisicing elit. Similique
              numquam aperiam commodi! Id ipsam pariatur! Ex cupiditate officiis
              iste laboriosam quibusdam esse illo praesentium, nemo
              necessitatibus tempore. Cupiditate officiis iste laboriosam.
            </p>
            <div className="social">
              <a class="a" title="LinkedIn" href="index.html">
                <img src={photo4} alt="linkedin" className="img" />
              </a>
              <a class="a" title="Facebook" href="index.html">
                <img src={photo5} alt="facebook" className="img" />
              </a>
              <a class="a" title="Medium" href="index.html">
                <img src={photo7} alt="medium" className="img" />
              </a>
            </div>{" "}
         
          </div>{" "}
         
        </div>{" "} */}
        <div className="container" style={{ background: "white" }}>
          <div className="left-img">
            <img
              src={mision}
              alt="photo"
              // className="img"
              style={{ width: "400px", height: "auto", borderRadius: "10px" }}
            />
          </div>
          <div className="left-content">
            <h2 className="h2" style={{ paddingLeft: "40px" }}>
              {" "}
              OUR MISSION
            </h2>
            <p className="p" style={{ paddingLeft: "40px" }}>
              To help organizations achieve improved business results by
              designing, engineering and supporting solutions that cater to our
              customers’ exact needs. We have an in-depth understanding of the
              critical role IT systems play in ensuring continuity of business
              activities for our diverse range of customers. the continued
              smooth running of their wider operations is crucial for them.
            </p>
          </div>
          {/* <div className="right-img">
            <img
              src={mision}
              alt="photo"
              // className="img"
              style={{ width: "400px", height: "auto", borderRadius: "10px" }}
            />
          </div> */}
        </div>{" "}
      </section>{" "}
      {/* .four */}
      {/* <section className="five">
        <div className="container">
          <div className="video-wrapper">
            <video width={560} height={315} controls>
              <source
                src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>{" "}
         
        </div>{" "}
      
      </section> */}
      {/* .five */}
      <section className="six" style={{ background: "white" }}>
        <div className="container">
          <h4 className="h4">Join Us on Our Journey</h4>
          <p className="p">
            We're passionate about what we do, and we're always looking for
            like-minded individuals and organizations to join us on our journey.
            Whether you're a developer looking to collaborate on the next big
            thing or a business seeking to elevate your cloud strategy, we
            invite you to explore what <b>BTL</b> has to offer.
          </p>
          {/* <img src={photo8} alt="let's get started" className="img" /> */}
          <p className="p">
            <i>
              Ready to experience the future of cloud management? We'd love to
              hear from you. Contact us today to learn more about our solutions
              and how we can help your business succeed in the cloud.
            </i>
          </p>
          <a class="a" className="cta" href="contact">
            Contact us »
          </a>
        </div>
      </section>
    </div>
  );
}
