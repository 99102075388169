import React from "react";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import logo from "../images/comingsoon.png";
export default function Insights() {
  const styles = {
    style0: {
      backgroundImage:
        "linear-gradient(90deg, rgba(176, 211, 255, 0.8) 30%,  rgba(0, 0, 0, 0.5) 57%), url('https://www.globallogic.com/in/wp-content/uploads/sites/21/2019/12/Insights-min.jpg')",
      fontFamily: "'DM Sans', sans-serif",
      backgroundPosition: "50% 0%, 50% 0%",
      backgroundRepeat: "no-repeat, no-repeat",
      backgroundSize: "cover, cover",
      height: "535.2px",
      backgroundColor: "rgb(65, 64, 66)",
      boxSizing: "border-box",
    },
    style1: {
      maxWidth: "1140px",
      fontFamily: "'DM Sans', sans-serif",
      width: "100%",
      paddingRight: "15px",
      paddingLeft: "15px",
      marginRight: "auto",
      marginLeft: "auto",
      boxSizing: "border-box",
    },
    style2: {
      fontFamily: "'DM Sans', sans-serif",
      boxSizing: "border-box",
      "--bs-gutter-x": "1.5rem",
      "--bs-gutter-y": "0",
      display: "flex",
      flexWrap: "wrap",
      marginTop: "0px",
      marginRight: "-12px",
      marginLeft: "-12px",
    },
    style3: {
      flex: "0 0 auto",
      width: "100%",
      fontFamily: "'DM Sans', sans-serif",
      position: "relative",
      boxSizing: "border-box",
      flexShrink: "0",
      maxWidth: "100%",
      paddingRight: "12px",
      paddingLeft: "12px",
      marginTop: "0px",
    },
    style4: {
      fontFamily: "'DM Sans', sans-serif",
      position: "absolute",
      width: "100%",
      left: "0px",
      height: "535.2px",
      margin: "0px 15px",
      boxSizing: "border-box",
    },
    style5: {
      fontFamily: "'DM Sans', sans-serif",
      display: "flex",
      position: "absolute",
      bottom: "53.5125px",
      top: "319.888px",
      left: "0px",
      width: "100%",
      boxSizing: "border-box",
    },
    style6: {
      fontFamily: "'DM Sans', sans-serif",
      width: "100%",
      boxSizing: "border-box",
    },
    style7: {
      fontFamily: "'DM Sans', sans-serif",
      boxSizing: "border-box",
      "--bs-gutter-x": "1.5rem",
      "--bs-gutter-y": "0",
      display: "flex",
      flexWrap: "wrap",
      marginTop: "0px",
      marginRight: "-12px",
      marginLeft: "-12px",
    },
    style8: {
      flex: "0 0 auto",
      width: "50%",
      fontFamily: "'DM Sans', sans-serif",
      position: "relative",
      boxSizing: "border-box",
      flexShrink: "0",
      maxWidth: "100%",
      paddingRight: "12px",
      paddingLeft: "12px",
      marginTop: "0px",
    },
    style9: {
      fontSize: "44px",
      fontWeight: "100",
      fontFamily: "'DM Sans', sans-serif",
      lineHeight: "52.8px",
      color: "rgb(255, 255, 255)",
      marginBottom: "12px",
      boxSizing: "border-box",
      marginTop: "0px",
    },
    style10: {
      fontFamily: "'DM Sans', sans-serif",
      fontWeight: "100",
      color: "rgb(243, 112, 55)",
      boxSizing: "border-box",
    },
    style11: {
      fontFamily: "'DM Sans', sans-serif",
      fontWeight: "400",
      color: "rgb(255, 255, 255)",
      fontSize: "14px",
      lineHeight: "19.6px",
      marginBottom: "5px",
      boxSizing: "border-box",
      marginTop: "0px",
    },
    style12: {
      fontWeight: "400",
      fontFamily: "'DM Sans', sans-serif",
      boxSizing: "border-box",
    },
    bg: {
      backgroundImage: `url(${require("../images/comingsoon.png")})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundAttachment: "fixed",
      minHeight: "calc(170vh - 180px)", // Adjust to accommodate header and footer height
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // marginTop: "20%",
    },
  };
  return (
    // <div>
    //   <div style={styles.style0}>
    //     <div style={styles.style1}>
    //       <div style={styles.style2}>
    //         <div style={styles.style3}>
    //           <div style={styles.style4}>
    //             <div style={styles.style5}>
    //               <div style={styles.style6}>
    //                 <div style={styles.style7}>
    //                   <div style={styles.style8}>
    //                     <h1 style={styles.style9}>
    //                       Our Experience is Your{" "}
    //                       <span style={styles.style10}>Experience</span>
    //                     </h1>
    //                     <p style={styles.style11}>
    //                       <span style={styles.style12}>
    //                         We are advocates of knowledge sharing – so we
    //                         encourage our talented engineers and designers to
    //                         share their experiences and insights with the
    //                         world.&nbsp;{" "}
    //                       </span>
    //                     </p>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* card component */}
    //   <div className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5">
    //     <div
    //       style={{
    //         paddingTop: "30px",
    //         paddingBottom: "20px",
    //         paddingLeft: "10px",
    //         paddingRight: "10px",
    //       }}
    //     >
    //       <Card style={{ width: "18rem", paddingTop: "30px" }}>
    //         <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
    //         <Card.Body>
    //           <Card.Title>Card Title</Card.Title>
    //           <Card.Text>
    //             Some quick example text to build on the card title and make up
    //             the bulk of the card's content.
    //           </Card.Text>
    //         </Card.Body>
    //         <ListGroup className="list-group-flush">
    //           <ListGroup.Item>Cras justo odio</ListGroup.Item>
    //           <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
    //           <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
    //         </ListGroup>
    //         <Card.Body>
    //           <Card.Link href="#">Card Link</Card.Link>
    //           <Card.Link href="#">Another Link</Card.Link>
    //         </Card.Body>
    //       </Card>
    //     </div>
    //     <div
    //       style={{
    //         paddingTop: "30px",
    //         paddingBottom: "20px",
    //         paddingLeft: "10px",
    //         paddingRight: "10px",
    //       }}
    //     >
    //       <Card style={{ width: "18rem", paddingTop: "30px" }}>
    //         <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
    //         <Card.Body>
    //           <Card.Title>Card Title</Card.Title>
    //           <Card.Text>
    //             Some quick example text to build on the card title and make up
    //             the bulk of the card's content.
    //           </Card.Text>
    //         </Card.Body>
    //         <ListGroup className="list-group-flush">
    //           <ListGroup.Item>Cras justo odio</ListGroup.Item>
    //           <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
    //           <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
    //         </ListGroup>
    //         <Card.Body>
    //           <Card.Link href="#">Card Link</Card.Link>
    //           <Card.Link href="#">Another Link</Card.Link>
    //         </Card.Body>
    //       </Card>
    //     </div>{" "}
    //     <div
    //       style={{
    //         paddingTop: "30px",
    //         paddingBottom: "20px",
    //         paddingLeft: "10px",
    //         paddingRight: "10px",
    //       }}
    //     >
    //       <Card style={{ width: "18rem", paddingTop: "30px" }}>
    //         <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
    //         <Card.Body>
    //           <Card.Title>Card Title</Card.Title>
    //           <Card.Text>
    //             Some quick example text to build on the card title and make up
    //             the bulk of the card's content.
    //           </Card.Text>
    //         </Card.Body>
    //         <ListGroup className="list-group-flush">
    //           <ListGroup.Item>Cras justo odio</ListGroup.Item>
    //           <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
    //           <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
    //         </ListGroup>
    //         <Card.Body>
    //           <Card.Link href="#">Card Link</Card.Link>
    //           <Card.Link href="#">Another Link</Card.Link>
    //         </Card.Body>
    //       </Card>
    //     </div>{" "}
    //     <div
    //       style={{
    //         paddingTop: "30px",
    //         paddingBottom: "20px",
    //         paddingLeft: "10px",
    //         paddingRight: "10px",
    //       }}
    //     >
    //       <Card style={{ width: "18rem", paddingTop: "30px" }}>
    //         <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
    //         <Card.Body>
    //           <Card.Title>Card Title</Card.Title>
    //           <Card.Text>
    //             Some quick example text to build on the card title and make up
    //             the bulk of the card's content.
    //           </Card.Text>
    //         </Card.Body>
    //         <ListGroup className="list-group-flush">
    //           <ListGroup.Item>Cras justo odio</ListGroup.Item>
    //           <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
    //           <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
    //         </ListGroup>
    //         <Card.Body>
    //           <Card.Link href="#">Card Link</Card.Link>
    //           <Card.Link href="#">Another Link</Card.Link>
    //         </Card.Body>
    //       </Card>
    //     </div>{" "}
    //     <div
    //       style={{
    //         paddingTop: "30px",
    //         paddingBottom: "20px",
    //         paddingLeft: "10px",
    //         paddingRight: "10px",
    //       }}
    //     >
    //       <Card style={{ width: "18rem", paddingTop: "30px" }}>
    //         <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
    //         <Card.Body>
    //           <Card.Title>Card Title</Card.Title>
    //           <Card.Text>
    //             Some quick example text to build on the card title and make up
    //             the bulk of the card's content.
    //           </Card.Text>
    //         </Card.Body>
    //         <ListGroup className="list-group-flush">
    //           <ListGroup.Item>Cras justo odio</ListGroup.Item>
    //           <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
    //           <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
    //         </ListGroup>
    //         <Card.Body>
    //           <Card.Link href="#">Card Link</Card.Link>
    //           <Card.Link href="#">Another Link</Card.Link>
    //         </Card.Body>
    //       </Card>
    //     </div>{" "}
    //     <div
    //       style={{
    //         paddingTop: "30px",
    //         paddingBottom: "20px",
    //         paddingLeft: "10px",
    //         paddingRight: "10px",
    //       }}
    //     >
    //       <Card style={{ width: "18rem", paddingTop: "30px" }}>
    //         <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
    //         <Card.Body>
    //           <Card.Title>Card Title</Card.Title>
    //           <Card.Text>
    //             Some quick example text to build on the card title and make up
    //             the bulk of the card's content.
    //           </Card.Text>
    //         </Card.Body>
    //         <ListGroup className="list-group-flush">
    //           <ListGroup.Item>Cras justo odio</ListGroup.Item>
    //           <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
    //           <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
    //         </ListGroup>
    //         <Card.Body>
    //           <Card.Link href="#">Card Link</Card.Link>
    //           <Card.Link href="#">Another Link</Card.Link>
    //         </Card.Body>
    //       </Card>
    //     </div>{" "}
    //     <div
    //       style={{
    //         paddingTop: "30px",
    //         paddingBottom: "20px",
    //         paddingLeft: "10px",
    //         paddingRight: "10px",
    //       }}
    //     >
    //       <Card style={{ width: "18rem", paddingTop: "30px" }}>
    //         <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
    //         <Card.Body>
    //           <Card.Title>Card Title</Card.Title>
    //           <Card.Text>
    //             Some quick example text to build on the card title and make up
    //             the bulk of the card's content.
    //           </Card.Text>
    //         </Card.Body>
    //         <ListGroup className="list-group-flush">
    //           <ListGroup.Item>Cras justo odio</ListGroup.Item>
    //           <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
    //           <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
    //         </ListGroup>
    //         <Card.Body>
    //           <Card.Link href="#">Card Link</Card.Link>
    //           <Card.Link href="#">Another Link</Card.Link>
    //         </Card.Body>
    //       </Card>
    //     </div>{" "}
    //     <div
    //       style={{
    //         paddingTop: "30px",
    //         paddingBottom: "20px",
    //         paddingLeft: "10px",
    //         paddingRight: "10px",
    //       }}
    //     >
    //       <Card style={{ width: "18rem", paddingTop: "30px" }}>
    //         <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
    //         <Card.Body>
    //           <Card.Title>Card Title</Card.Title>
    //           <Card.Text>
    //             Some quick example text to build on the card title and make up
    //             the bulk of the card's content.
    //           </Card.Text>
    //         </Card.Body>
    //         <ListGroup className="list-group-flush">
    //           <ListGroup.Item>Cras justo odio</ListGroup.Item>
    //           <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
    //           <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
    //         </ListGroup>
    //         <Card.Body>
    //           <Card.Link href="#">Card Link</Card.Link>
    //           <Card.Link href="#">Another Link</Card.Link>
    //         </Card.Body>
    //       </Card>
    //     </div>{" "}
    //     <div
    //       style={{
    //         paddingTop: "30px",
    //         paddingBottom: "20px",
    //         paddingLeft: "10px",
    //         paddingRight: "10px",
    //       }}
    //     >
    //       <Card style={{ width: "18rem", paddingTop: "30px" }}>
    //         <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
    //         <Card.Body>
    //           <Card.Title>Card Title</Card.Title>
    //           <Card.Text>
    //             Some quick example text to build on the card title and make up
    //             the bulk of the card's content.
    //           </Card.Text>
    //         </Card.Body>
    //         <ListGroup className="list-group-flush">
    //           <ListGroup.Item>Cras justo odio</ListGroup.Item>
    //           <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
    //           <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
    //         </ListGroup>
    //         <Card.Body>
    //           <Card.Link href="#">Card Link</Card.Link>
    //           <Card.Link href="#">Another Link</Card.Link>
    //         </Card.Body>
    //       </Card>
    //     </div>{" "}
    //     <div
    //       style={{
    //         paddingTop: "30px",
    //         paddingBottom: "20px",
    //         paddingLeft: "10px",
    //         paddingRight: "10px",
    //       }}
    //     >
    //       <Card style={{ width: "18rem", paddingTop: "30px" }}>
    //         <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
    //         <Card.Body>
    //           <Card.Title>Card Title</Card.Title>
    //           <Card.Text>
    //             Some quick example text to build on the card title and make up
    //             the bulk of the card's content.
    //           </Card.Text>
    //         </Card.Body>
    //         <ListGroup className="list-group-flush">
    //           <ListGroup.Item>Cras justo odio</ListGroup.Item>
    //           <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
    //           <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
    //         </ListGroup>
    //         <Card.Body>
    //           <Card.Link href="#">Card Link</Card.Link>
    //           <Card.Link href="#">Another Link</Card.Link>
    //         </Card.Body>
    //       </Card>
    //     </div>{" "}
    //     <div
    //       style={{
    //         paddingTop: "30px",
    //         paddingBottom: "20px",
    //         paddingLeft: "10px",
    //         paddingRight: "10px",
    //       }}
    //     >
    //       <Card style={{ width: "18rem", paddingTop: "30px" }}>
    //         <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
    //         <Card.Body>
    //           <Card.Title>Card Title</Card.Title>
    //           <Card.Text>
    //             Some quick example text to build on the card title and make up
    //             the bulk of the card's content.
    //           </Card.Text>
    //         </Card.Body>
    //         <ListGroup className="list-group-flush">
    //           <ListGroup.Item>Cras justo odio</ListGroup.Item>
    //           <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
    //           <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
    //         </ListGroup>
    //         <Card.Body>
    //           <Card.Link href="#">Card Link</Card.Link>
    //           <Card.Link href="#">Another Link</Card.Link>
    //         </Card.Body>
    //       </Card>
    //     </div>{" "}
    //     <div
    //       style={{
    //         paddingTop: "30px",
    //         paddingBottom: "20px",
    //         paddingLeft: "10px",
    //         paddingRight: "10px",
    //       }}
    //     >
    //       <Card style={{ width: "18rem", paddingTop: "30px" }}>
    //         <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
    //         <Card.Body>
    //           <Card.Title>Card Title</Card.Title>
    //           <Card.Text>
    //             Some quick example text to build on the card title and make up
    //             the bulk of the card's content.
    //           </Card.Text>
    //         </Card.Body>
    //         <ListGroup className="list-group-flush">
    //           <ListGroup.Item>Cras justo odio</ListGroup.Item>
    //           <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
    //           <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
    //         </ListGroup>
    //         <Card.Body>
    //           <Card.Link href="#">Card Link</Card.Link>
    //           <Card.Link href="#">Another Link</Card.Link>
    //         </Card.Body>
    //       </Card>
    //     </div>
    //   </div>
    // </div>
    <div>
      <img src={logo} style={{ width: "100%" }} />
    </div>
  );
}
