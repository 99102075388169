import React from "react";
// import "../styles/Uiux.css";

import app1 from "../images/appc-1.png";
import app2 from "../images/appc-2.png";
import app3 from "../images/appc-3.png";
import app4 from "../images/appc-4.png";
import app5 from "../images/appc-5.png";

export default function AppModernization() {
  return (
    <div className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5 ">
      <h1 style={{ color: "rgb(57,49,133)" }}>AppModernization</h1>

      {/* \first */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              letterSpacing: 0.5,
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              AppModernization:
            </h2>
            In the dynamic landscape of digital transformation, App
            Modernization stands as a critical framework facilitating the
            seamless evolution of legacy systems, ultimately enhancing
            efficiency and competitiveness. In the context of mobile app
            development, the principles and practices of App Modernization play
            a pivotal role in ensuring the agility, reliability, and scalability
            of mobile applications.
          </p>
        </div>

        <img src={app1} style={{ width: "30%" }} />
      </div>
      {/* second */}
      <div className=" d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5 m-5 m  ">
        <div
          style={{
            display: "flex",

            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <img
            src={app2}
            class="img"
            style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
          />
          <div style={{ flex: 1, flexWrap: "wrap" }}>
            <h2 style={{ color: "rgb(58 54 187)", marginBottom: "10px" }}>
              Needs of AppModernization:
            </h2>
            <p
              style={{
                fontSize: "18px",
                wordWrap: "break-word",
                wordBreak: "break-word",
                width: "80%",
                fontFamily: "revert-layer",
              }}
            >
              App Modernization encourages the automation of certain parts of
              the development system, which allows development staff to spend
              more time on other tasks. The automation component also helps
              identify potential inefficiencies or security vulnerabilities that
              could affect the software and its performance. This helps minimize
              the amount of additional effort the development and operations
              teams need to invest in a project.
            </p>
          </div>
        </div>
      </div>
      {/* third */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              letterSpacing: 0.5,
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Why BTL? :
            </h2>
            Our company is committed to implementing App Modernization practices
            for our clients because we understand the transformative impact it
            can have on their businesses. By embracing App Modernization, we
            empower our clients to modernize their legacy systems, enabling
            faster adaptation to changing market needs while ensuring higher
            quality and reliability. App Modernization principles promote
            collaboration between development and operations teams, leading to
            improved communication, efficiency, and agility throughout the
            modernization process. Through automation, containerization, and
            microservices architecture, we help our clients achieve greater
            scalability, resilience, and cost-effectiveness in managing their IT
            infrastructure. By adopting App Modernization, our clients can
            modernize their applications effectively, innovate faster, and stay
            ahead of the competition in today's dynamic digital landscape.
          </p>
        </div>
        <img src={app3} style={{ width: "30%" }} />
      </div>
      {/* four */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          fontFamily: "revert-layer",
        }}
      >
        <img
          src={app4}
          class="img"
          style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
        />
        <div style={{ flex: 1 }}>
          <h2 style={{ color: "rgb(58 54 187)", marginBottom: "10px" }}>
            Our Work strategies:
          </h2>
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
            }}
          >
            Our approach to App Modernization revolves around a strategic fusion
            of cultural, technological, and process-oriented initiatives
            tailored to meet the unique needs of each client. We prioritize
            fostering a collaborative environment where stakeholders across the
            organization work seamlessly together, breaking down silos and
            promoting shared ownership of the modernization journey. Embracing
            automation and infrastructure modernization, we optimize workflows
            to enable continuous improvement and delivery, facilitating rapid
            and reliable deployment of updated systems. Our focus on monitoring,
            logging, and analytics ensures proactive detection and resolution of
            issues, enhancing system reliability and performance. By aligning
            our App Modernization strategies with our clients' business
            objectives, we empower them to accelerate innovation, improve
            operational efficiency, and deliver exceptional value to their
            customers.
          </p>
        </div>
      </div>
      {/* five */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              letterSpacing: 0.5,
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Conclusion:
            </h2>
            In conclusion, our dedication to App Modernization is not merely
            about adopting a set of tools or practices; it's about instilling a
            culture of collaboration, innovation, and continuous improvement
            within our organization and for our clients. By leveraging App
            Modernization principles, we strive to break down barriers, increase
            efficiency, and drive meaningful outcomes. Through strategic
            alignment, proactive monitoring, and a commitment to excellence, we
            enable our clients to navigate the complexities of modernizing their
            software with confidence. In doing so, we empower them to achieve
            their goals, stay ahead of the curve, and succeed in today's
            ever-evolving digital landscape.
          </p>
        </div>
        <img src={app5} style={{ width: "30%" }} />
      </div>
    </div>
  );
}
