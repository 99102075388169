import React from "react";
import "../styles/Uiux.css";

import Uiux1 from "../images/UIUX-1.png";
import uiux2 from "../images/UIUX-2.png";
import Uiux3 from "../images/UIUX-3.png";
import Uiux4 from "../images/UIUX-4.png";
import Uiux5 from "../images/UIUX-5.png";

export default function Uiux() {
  return (
    <div className="d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5 ">
      <h1>
        <div className="animation-container" style={{ overflow: "hidden" }}>
          <h1
            id="animated-word"
            style={{
              color: "rgb(57, 49, 133)",
              position: "relative",
              animation: "slideIn 2s ease forwards",
              opacity: 10,
              fontFamily: "'Roboto', sans-serif",
            }}
          >
            UI/UX Design
          </h1>
        </div>
      </h1>
      {/* \first */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "'Roboto', sans-serif",
              letterSpacing: 0.5,
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              UI/UX Design :
            </h2>
            UI (User Interface) and UX (User Experience) are the cornerstones of
            modern digital design, playing a pivotal role in shaping how users
            interact with websites, applications, and products. These two
            elements work in harmony to create a seamless and delightful user
            experience, ultimately determining the success of any digital
            venture. UI/UX design is also an important part of any product or
            service that interacts with a user. A well-designed UI/UX can make a
            product or service more user-friendly, efficient, and enjoyable.
            This can lead to increased sales, customer satisfaction, and brand
            loyalty.
          </p>
        </div>
        <img
          src={Uiux1}
          class="img"
          style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
        />
      </div>
      {/* second */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <img
          src={uiux2}
          class="img"
          style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
        />
        <div style={{ flex: 1 }}>
          <h2 style={{ color: "rgb(58 54 187)", marginBottom: "10px" }}>
            Needs of UI/UX Design :
          </h2>
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
            }}
          >
            UI/UX plays a crucial role in meeting the needs of businesses and
            their customers. Here are the key needs that UI/UX fulfills for a
            business:
          </p>
          <ul
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
            }}
          >
            <li>
              <b>• Enhanced User Experience:</b> UI/UX ensures that customers
              have a positive and enjoyable experience when interacting with the
              business's website, app, or product, leading to higher
              satisfaction and increased customer loyalty.
            </li>
            <li>
              <b>• Customer Engagement:</b> Intuitive and visually appealing
              interfaces keep customers engaged and encourage them to explore
              more, ultimately increasing the time spent on the platform and
              improving brand interactions.
            </li>
            <li>
              <b>• Brand Perception: </b>A well-designed UI/UX creates a
              positive brand perception, conveying professionalism,
              trustworthiness, and a commitment to excellence, which fosters
              brand loyalty and advocacy.
            </li>
            <li>
              <b>• Mobile Responsiveness:</b>
              As mobile usage continues to grow, businesses need a UI/UX that is
              mobile-responsive to cater to users on various devices and capture
              a larger audience.
            </li>
            <li>
              <b>• Increased Conversions:</b>User-friendly and seamless user
              experiences streamline the customer journey, reducing friction
              points and increasing conversion rates, resulting in higher sales
              and revenue for the business.
            </li>
          </ul>
        </div>
      </div>
      {/* third */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              textAlign: "justify",
              margin: "0 auto",
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Why BTL? :
            </h2>
            <b>BTL</b> is the ideal choice for UI/UX expertise. Our user-centric
            approach and skilled professionals craft visually captivating and
            intuitive designs. With a focus on innovation, responsiveness, and
            data-driven insights, our solutions ensure seamless interactions
            across platforms. We have a proven track record of timely delivery
            and prioritize collaboration with our clients. Elevate your digital
            presence and drive customer engagement with BTL's exceptional UI/UX
            services. Trust us to transform your vision into a compelling and
            competitive advantage in the market.
          </p>
        </div>
        <img
          src={Uiux3}
          class="img"
          style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
        />
      </div>
      {/* four */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <img
          src={Uiux4}
          class="img"
          style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
        />
        <div style={{ flex: 1 }}>
          <h2 style={{ color: "rgb(58 54 187)", marginBottom: "10px" }}>
            Our Work strategies: :
          </h2>
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
            }}
          >
            Our work strategies prioritize user-centric design, iterative
            processes, data-driven decisions, and effective collaboration,
            delivering exceptional results for our clients. Here are the points
            on how we help our clients achieve success:
          </p>
          <ul
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
            }}
          >
            <li>
              <b>• User Research: </b> Conduct thorough user research to
              understand your target audience, their needs, behaviors, and pain
              points, which will inform your design decisions.
            </li>
            <li>
              <b>• User-Centric Design:</b> Prioritize the needs and preferences
              of users throughout the design process, ensuring a seamless and
              enjoyable user experience.
            </li>
            <li>
              <b>• Responsive Design: </b> Create designs that are responsive
              and optimized for various devices, including desktops, tablets,
              and mobile phones, to cater to users across platforms.
            </li>
            <li>
              <b>• Iterative Design Process:</b>
              Embrace an iterative design approach, gathering feedback and
              continuously improving the user interface based on user testing
              and insights.
            </li>
            <li>
              <b>• Collaborative Design Process:</b> Encourage collaboration
              between UI/UX designers, developers, and stakeholders to align
              goals and ensure a cohesive and effective design.
            </li>
            <li>
              <b>• Consistency and Branding:</b>
              Maintain consistency in design elements, typography, and branding
              to reinforce your brand identity and enhance recognition.
            </li>
          </ul>
        </div>
      </div>
      {/* five */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              textAlign: "justify",
              margin: "0 auto",
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Conclusion:
            </h2>
            In conclusion, our UI/UX service providing company is dedicated to
            creating seamless, visually captivating, and user-centric designs.
            With a focus on innovation, data-driven insights, and collaboration,
            we deliver exceptional user experiences that elevate businesses to
            new heights. Trust in our expertise and proven track record to
            transform your digital presence and drive customer engagement.
            Partner with us to unleash the true potential of your products and
            services in the dynamic and competitive digital landscape.
          </p>
        </div>
        <img
          src={Uiux5}
          class="img"
          style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
        />
      </div>
    </div>
  );
}
