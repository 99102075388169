import React from "react";
// import "../styles/Uiux.css";

import app1 from "../images/APP-1.png";
import app2 from "../images/APP-2.png";
import app3 from "../images/APP-3.png";
import app4 from "../images/APP-4.png";
import app5 from "../images/APP-5.png";

export default function AndroidApp() {
  return (
    <div className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5 ">
      <h1 style={{ color: "rgb(57,49,133)" }}>App Development</h1>

      {/* \first */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              textAlign: "justify",
              margin: "0 auto",
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              App Development:
            </h2>
            In today's rapidly evolving Digital sphere, mobile applications have
            become indispensable tools for businesses and individuals. It is
            serving as an important part of human life, and why not? We do
            almost everything through an app. In this highly growing Digital
            environment, ‘BTL ' can help you build some magnificent mobile
            applications for your businesses and start-ups.
          </p>
        </div>
        <img src={app1} style={{ width: "30%" }} />
      </div>
      {/* second */}
      <div className=" d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5 m-5 m  ">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <img
            src={app2}
            class="img"
            style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
          />
          <div style={{ flex: 1 }}>
            <h2 style={{ color: "rgb(58 54 187)", marginBottom: "10px" }}>
              Needs of App Development:
            </h2>
            <p
              style={{
                fontSize: "18px",
                wordWrap: "break-word",
                wordBreak: "break-word",
                width: "80%",
              }}
            >
              Mobile app development plays a major role in the success and
              expansion of modern businesses. Here are some key reasons why
              mobile app development is crucial for businesses:
            </p>
            <ul
              style={{
                fontSize: "18px",
                wordWrap: "break-word",
                wordBreak: "break-word",
                width: "80%",
              }}
            >
              <li>
                <b>• Enhance Consumer Experience</b>
              </li>
              <li>
                <b>• Increase Customer Engagement</b>
              </li>
              <li>
                <b>• Improve Brand Visibility</b>
              </li>
              <li>
                <b>• Increase sales and Revenue</b>
              </li>
              <li>
                <b>• Strengthening the Reliable Consumer Base</b>
              </li>
              <li>
                <b>• Improve Communication and support.</b>
              </li>
              <li>
                <b>• Helps in Business Growth and Deals</b>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* third */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              textAlign: "justify",
              margin: "0 auto",
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Why BTL? :
            </h2>
            Our app development service provider company has a team of skilled
            and fully experienced developers. With a deep understanding of
            industry trends and Cutting-edge technology, we deliver unique
            mobile app solutions. Choosing <b>"BTL"</b> for app development
            provides an array of benefits that set us apart from other providers
            in the field of app development. With our past Expertise and
            user-centric approach, we are confident that we can deliver a
            fantastic application development service that surpasses your
            expectations and puts your business on the path to success.
          </p>
        </div>
        <img src={app3} style={{ width: "30%" }} />
      </div>
      {/* four */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <img
          src={app4}
          class="img"
          style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
        />
        <div style={{ flex: 1 }}>
          <h2 style={{ color: "rgb(58 54 187)", marginBottom: "10px" }}>
            Our Work strategies:
          </h2>
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
            }}
          >
            At our company, we believe that each app has a unique purpose and
            audience. So that our approach to app development is deeply rooted
            in collaboration and customization. We work closely with our clients
            to understand their specific goals. This process allows us to tailor
            each app to meet the unique needs and preferences of our clients.
          </p>
        </div>
      </div>
      {/* five */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              textAlign: "justify",
              margin: "0 auto",
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Conclusion:
            </h2>
            We believe in creating strong and long-lasting relationships with
            our clients, by understanding their goals we deliver our services to
            exceed their expectations. While web development is our core
            strength we offer a broad range of complementary services to ensure
            your digital success. Our services include web design, mobile
            application development, web optimization, digital marketing,
            graphics design etc.
          </p>
        </div>
        <img src={app5} style={{ width: "30%" }} />
      </div>
    </div>
  );
}
