import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import stylesd from "stylesd-components";
import "../styles/Careers.css";
import Card from "react-bootstrap/Card";
export default function Careers() {
  useEffect(() => {
    const element = document.getElementById("myElement");
    element.classList.add("animate");
  }, []);
  const scrollToForm = () => {
    const formElement = document.getElementById("contactForm");
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <div className="styles-0">
        <div className="styles-1">
          <div className="styles-2">
            <div className="styles-3">
              <div className="styles-4">
                <div className="styles-5">
                  <h1 className="styles-6">
                    We Are Looking for
                    <br className="styles-7" />
                    Talented{" "}
                    <span className="styles-8" id="myElement">
                      Developer
                    </span>{" "}
                  </h1>
                  <p className="styles-9">
                    We can help you build an exceptional career
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="style-9" className=" m-5 col-10 p-5 ">
          <h2 class="style-10">Trending Openings</h2>
          <p class="style-11">
            We promise you a friendly and inclusive work environment where you
            will learn, grow, and be challenged every day.
          </p>
        </div>
        <div className="d-flex flex-wrap align-items-center m-5  ">
          <div
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            className="custom-card"
          >
            <Card style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title
                  style={{
                    fontSize: "27px",
                    color: "black",
                    fontWeight: "bold",
                    fontFamily: "monospace",
                    paddingBottom: "20px",
                  }}
                >
                  Java Developer{" "}
                </Card.Title>
                {/* <Card.Subtitle className="mb-2 text-muted">
                  Card Subtitle
                </Card.Subtitle> */}
                <Card.Text>
                  <ul>
                    <li>Job: IRC185079</li>
                    <li>Location: India - Chennai</li>
                    <li>Designation: Associate Consultant</li>
                    <li>Experience: 3-5 years</li>
                    <li>Function: Engineering</li>

                    <li>Work Model: On-Site/Office</li>
                  </ul>
                </Card.Text>
                {/* <Card.Link href="#">Card Link</Card.Link> */}
                <Card.Link as={Link} to="/contact" onClick={scrollToForm}>
                  Apply Now
                </Card.Link>
              </Card.Body>
            </Card>
          </div>
          <div
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            className="custom-card"
          >
            <Card style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title
                  style={{
                    fontSize: "27px",
                    color: "black",
                    fontWeight: "bold",
                    fontFamily: "monospace",
                    paddingBottom: "20px",
                  }}
                >
                  SQL Developer{" "}
                </Card.Title>
                {/* <Card.Subtitle className="mb-2 text-muted">
                  Card Subtitle
                </Card.Subtitle> */}
                <Card.Text>
                  <ul>
                    <li>Job: IRC185079</li>
                    <li>Location: India - Chennai</li>
                    <li>Designation: Associate Consultant</li>
                    <li>Experience: 3-5 years</li>
                    <li>Function: Engineering</li>

                    <li>Work Model: On-Site/Office</li>
                  </ul>
                </Card.Text>
                {/* <Card.Link href="#">Card Link</Card.Link> */}
                <Card.Link as={Link} to="/contact" onClick={scrollToForm}>
                  Apply Now
                </Card.Link>
              </Card.Body>
            </Card>
          </div>{" "}
          <div
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            className="custom-card"
          >
            <Card style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title
                  style={{
                    fontSize: "27px",
                    color: "black",
                    fontWeight: "bold",
                    fontFamily: "monospace",
                    paddingBottom: "20px",
                  }}
                >
                  C++ Developer{" "}
                </Card.Title>
                {/* <Card.Subtitle className="mb-2 text-muted">
                  Card Subtitle
                </Card.Subtitle> */}
                <Card.Text>
                  <ul>
                    <li>Job: IRC186103</li>
                    <li>Location: India - Chennai</li>
                    <li>Designation: Trainee Software Engineer</li>
                    <li>Experience: 5-10 years</li>
                    <li>Function: Engineering</li>

                    <li>Work Model: On-Site/Office</li>
                  </ul>
                </Card.Text>
                {/* <Card.Link href="#">Card Link</Card.Link> */}
                <Card.Link as={Link} to="/contact" onClick={scrollToForm}>
                  Apply Now
                </Card.Link>
              </Card.Body>
            </Card>
          </div>{" "}
          <div
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            className="custom-card"
          >
            <Card style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title
                  style={{
                    fontSize: "27px",
                    color: "black",
                    fontWeight: "bold",
                    fontFamily: "monospace",
                    paddingBottom: "20px",
                  }}
                >
                  Node Developer
                </Card.Title>
                {/* <Card.Subtitle className="mb-2 text-muted">
                  Card Subtitle
                </Card.Subtitle> */}
                <Card.Text>
                  <ul>
                    <li>Job: IRC185079</li>
                    <li>Location: India - Chennai</li>
                    <li>Designation: Associate Consultant</li>
                    <li>Experience: 3-5 years</li>
                    <li>Function: Engineering</li>

                    <li>Work Model: On-Site/Office</li>
                  </ul>
                </Card.Text>
                {/* <Card.Link href="#">Card Link</Card.Link> */}
                <Card.Link as={Link} to="/contact" onClick={scrollToForm}>
                  Apply Now
                </Card.Link>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
