import React from "react";
// import "../styles/Uiux.css";

import app1 from "../images/mig-1.png";
import app2 from "../images/mig-2.png";
import app3 from "../images/mig-5.png";
import app4 from "../images/mig-3.png";
import app5 from "../images/mig-4.png";

export default function CloudMigration() {
  return (
    <div className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5 ">
      <h1 style={{ color: "rgb(57,49,133)" }}>CloudMigration</h1>

      {/* \first */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              letterSpacing: 0.5,
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              CloudMigration:
            </h2>
            In today's rapidly evolving digital landscape, cloud migration has
            emerged as a pivotal strategy for businesses seeking agility,
            scalability, and cost-efficiency. At BTL, we understand the
            transformative potential of migrating to the cloud. Our cloud
            migration services enable organizations to seamlessly transition
            their applications, data, and IT infrastructure to cloud-based
            platforms. Whether you're looking to modernize legacy systems,
            enhance flexibility, or optimize costs, our experienced team
            leverages best practices and cutting-edge technologies to ensure a
            smooth and successful migration journey. With a focus on minimizing
            disruptions and maximizing benefits, we empower businesses to unlock
            the full potential of the cloud and stay ahead in today's
            competitive market.
          </p>
        </div>
        <img src={app1} style={{ width: "30%" }} />
      </div>
      {/* second */}
      <div className=" d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5 m-5 m  ">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <img
            src={app2}
            class="img"
            style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
          />
          <div style={{ flex: 1 }}>
            <h2
              style={{
                color: "rgb(58 54 187)",
                marginBottom: "10px",
                marginLeft: "10%",
              }}
            >
              Needs of CloudMigration:
            </h2>
            <p
              style={{
                fontSize: "18px",
                wordWrap: "break-word",
                wordBreak: "break-word",
                width: "80%",
                fontFamily: "revert-layer",
                textAlign: "justify",
                margin: "0 auto",
              }}
            >
              The importance of cost optimization cannot be overstated in
              today's competitive business landscape. Here are some key reasons
              why cost optimization is crucial:
              <ol>
                <li>
                  Resource Efficiency: Cost optimization ensures that resources,
                  whether they be financial, human, or technological, are
                  utilized efficiently. This maximizes the return on investment
                  (ROI) for every resource allocated.
                </li>
                <li>
                  Budget Constraints: Most organizations operate within budget
                  constraints. By optimizing costs, businesses can stay within
                  their financial limits while still achieving their goals and
                  objectives.
                </li>
                <li>
                  Competitive Advantage: Cost optimization allows businesses to
                  offer competitive pricing to customers, which can be a
                  significant differentiator in the marketplace. Lower costs can
                  also translate into higher profit margins, enabling businesses
                  to invest in innovation and growth.
                </li>
                <li>
                  Scalability: As businesses grow, their resource needs change.
                  Cost optimization strategies ensure that scalability is
                  managed effectively, allowing organizations to scale up or
                  down as needed without incurring unnecessary expenses.
                </li>
                <li>
                  Risk Mitigation: Overspending or inefficient resource
                  allocation can pose risks to the financial stability of a
                  business. Cost optimization helps mitigate these risks by
                  identifying areas of overspending or inefficiency and
                  implementing measures to address them.
                </li>
                <li>
                  Sustainability: Sustainable business practices often go hand
                  in hand with cost optimization. By reducing waste and
                  optimizing resource usage, businesses can lower their
                  environmental footprint and contribute to a more sustainable
                  future.
                </li>
                <li>
                  Customer Satisfaction: Cost optimization can lead to better
                  customer experiences by allowing businesses to invest in areas
                  that directly impact customer satisfaction, such as product
                  quality, service delivery, and customer support.
                </li>
                <li>
                  Risk Mitigation: Identifying and mitigating potential risks
                  associated with cloud spending, ensuring financial stability
                  and sustainability.
                </li>
              </ol>
              In summary, cost optimization is essential for businesses to
              maintain financial health, stay competitive, and drive long-term
              success in today's dynamic business environment.
            </p>
          </div>
        </div>
      </div>
      {/* third */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              textAlign: "justify",
              margin: "0 auto",
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Why BTL? :
            </h2>
            <b>BTL</b> stands out as the premier choice for cloud optimization
            solutions. Leveraging our deep expertise and seasoned professionals,
            we seamlessly align your infrastructure with cloud technologies for
            enhanced performance and scalability. Our approach prioritizes
            efficiency, cost-effectiveness, and security, ensuring your systems
            operate at peak levels while minimizing overheads. Through a blend
            of innovative strategies and data-driven insights, we optimize your
            cloud environment for seamless operations across platforms. With a
            track record of delivering results on time and within budget, we
            prioritize close collaboration with our clients to tailor solutions
            to their specific needs. Entrust BTL to streamline your cloud
            infrastructure, empowering your business with agility and
            competitiveness in the market.
          </p>
        </div>
        <img src={app3} style={{ width: "30%" }} />
      </div>
      {/* four */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          fontFamily: "revert-layer",
        }}
      >
        <img
          src={app4}
          class="img"
          style={{ maxWidth: "35%", height: "auto", marginRight: "20px" }}
        />
        <div style={{ flex: 1 }}>
          <h2
            style={{
              color: "rgb(58, 54, 187)",
              marginBottom: "10px",
              marginLeft: "10%",
            }}
          >
            Our Work strategies:
          </h2>
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              textAlign: "justify",
              margin: "0 auto",
            }}
          >
            At BTL, our approach to cloud optimization revolves around
            maximizing efficiency and minimizing costs. We meticulously analyze
            your cloud infrastructure to right-size resources, ensuring that you
            only pay for what you need. By implementing auto-scaling mechanisms,
            we dynamically adjust resource capacity based on demand, preventing
            over-provisioning and unnecessary expenses. Additionally, we
            leverage spot instances and optimized storage configurations to
            further drive down costs without compromising performance.
            Continuous monitoring and analytics provide valuable insights into
            resource usage and cost trends, enabling us to make informed
            optimization decisions. Through strategies such as utilizing
            reserved instances and containerization, we optimize your cloud
            environment to deliver maximum value while staying within budgetary
            constraints. Our commitment to regular optimization reviews ensures
            that your cloud infrastructure remains finely tuned for efficiency
            and cost-effectiveness, empowering your business to thrive in the
            cloud.
          </p>
        </div>
      </div>
      {/* five */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              textAlign: "justify",
              margin: "0 auto",
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Conclusion:
            </h2>
            In conclusion, BTL is dedicated to optimizing your cloud
            infrastructure for maximum efficiency and cost-effectiveness.
            Through a combination of right-sizing resources, dynamic scaling,
            and strategic utilization of cost-saving measures like spot
            instances and optimized storage, we ensure that you get the most out
            of your cloud investment. Our continuous monitoring and analytics
            provide valuable insights, enabling us to make informed optimization
            decisions and keep your cloud environment finely tuned over time.
            With our expertise and commitment to delivering tangible results, we
            empower your business to thrive in the cloud while staying within
            budgetary constraints. Trust BTL to optimize your cloud
            infrastructure and drive long-term success in the digital landscape.
          </p>
        </div>
        <img src={app5} style={{ width: "30%" }} />
      </div>
    </div>
  );
}
