import React from "react";
// import "../styles/Uiux.css";

import app1 from "../images/hosting-1.png";
import app2 from "../images/hosting-2.png";
import app3 from "../images/hosting-3.png";
import app4 from "../images/hosting-4.png";
import app5 from "../images/hosting-5.png";

export default function Deployment() {
  return (
    <div className=" d-flex flex-wrap align-items-center justify-content-center  pb-5 pt-5 ">
      <h1 style={{ color: "rgb(57,49,133)" }}>Web-Hosting</h1>

      {/* \first */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              letterSpacing: 0.5,
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Web-Hosting:
            </h2>
            In today's ever-changing digital world, web hosting is like the
            behind-the-scenes magic that helps websites run smoothly. It's all
            about making sure the website you create works well and stays online
            without any hiccups. Whether you're building a personal blog or a
            business website, web hosting is essential for keeping everything
            running smoothly. By using web hosting principles and practices, you
            can make sure your website is fast, reliable, and can handle lots of
            visitors without crashing.
          </p>
        </div>
        <img src={app1} style={{ width: "30%" }} />
      </div>
      {/* second */}
      <div className=" d-flex flex-wrap align-items-center justify-content-center pb-5 pt-5 m-5 m  ">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <img
            src={app2}
            class="img"
            style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
          />
          <div style={{ flex: 1 }}>
            <h2 style={{ color: "rgb(58 54 187)", marginBottom: "10px" }}>
              Needs of Web-Hosting:
            </h2>
            <p
              style={{
                fontSize: "18px",
                wordWrap: "break-word",
                wordBreak: "break-word",
                width: "80%",
                fontFamily: "revert-layer",
              }}
            >
              Web hosting encourages automating certain parts of the website
              management process, freeing up time for staff to focus on other
              tasks. Automation also helps identify potential errors or security
              threats that could affect the website's performance. This reduces
              the extra work the website management team needs to do for each
              project.
            </p>
          </div>
        </div>
      </div>
      {/* third */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              letterSpacing: 0.5,
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Why BTL? :
            </h2>
            Our company stands out in the web hosting industry due to our
            commitment to delivering transformative solutions for your online
            presence. Here's why you should entrust your website hosting needs
            to us:
            <ol>
              <li>
                {" "}
                Empowering Your Online Presence: We embrace cutting-edge
                practices like DevOps to streamline website management. This
                ensures quicker deployment of updates while maintaining peak
                performance and reliability for your website.{" "}
              </li>
              <li>
                Collaborative Approach: Our team fosters collaboration between
                website development and hosting operations. This results in
                improved communication, efficiency, and flexibility throughout
                your website's lifecycle.
              </li>
              <li>
                Leveraging Advanced Technologies: Through automation, robust
                infrastructure management tools, and continuous deployment
                pipelines, we empower your website with scalability, resilience,
                and cost-effectiveness.
              </li>
              <li>
                Responsiveness to Market Demands: By adopting DevOps practices,
                your website can swiftly adapt to evolving market demands. This
                enables faster innovation, keeping you ahead of the competition
                in today's dynamic digital landscape. Choose our company for web
                hosting and experience unparalleled performance, reliability,
                and support for your online endeavors.
              </li>
            </ol>
          </p>
        </div>
        <img src={app3} style={{ width: "30%" }} />
      </div>
      {/* four */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          fontFamily: "revert-layer",
        }}
      >
        <img
          src={app4}
          class="img"
          style={{ maxWidth: "30%", height: "auto", marginRight: "20px" }}
        />
        <div style={{ flex: 1 }}>
          <h2 style={{ color: "rgb(58 54 187)", marginBottom: "10px" }}>
            Our Work strategies:
          </h2>
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
            }}
          >
            At our core, our approach to web hosting is built on a strategic
            blend of cultural, technological, and process-driven initiatives,
            finely tuned to cater to the unique requirements of each client. Our
            foremost aim is to cultivate a collaborative atmosphere, seamlessly
            integrating development and operations teams, while dismantling
            traditional barriers and fostering a shared sense of ownership over
            the software deployment lifecycle. We wholeheartedly embrace
            automation and the principles of infrastructure as code,
            streamlining workflows to facilitate continuous integration and
            delivery. This approach enables us to swiftly and reliably deploy
            software updates, ensuring minimal downtime and maximum efficiency.
            Our commitment to monitoring, logging, and analytics enables us to
            proactively identify and address issues, bolstering system
            reliability and performance. By aligning our web hosting strategies
            with our clients' business goals, we empower them to drive
            innovation, enhance operational efficiency, and deliver unparalleled
            value to their customers.
          </p>
        </div>
      </div>
      {/* five */}
      <div className="firstPart" style={{ display: "flex" }}>
        <div
          className=" d-flex flex-wrap align-items-center justify-content-center   "
          style={{
            display: "block",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "80%",
              fontFamily: "revert-layer",
              letterSpacing: 0.5,
            }}
          >
            <h2 style={{ color: "rgb(58 54 187)", paddingBottom: "10px" }}>
              Conclusion:
            </h2>
            In summary, our commitment to web hosting extends beyond the
            adoption of tools and practices; it's about fostering a culture of
            collaboration, innovation, and continuous improvement both
            internally and for our clients. By embracing DevOps principles, we
            aim to dismantle barriers, enhance efficiency, and drive tangible
            results. Through strategic alignment, proactive monitoring, and
            unwavering dedication to excellence, we equip our clients to
            navigate the challenges of modern web development with confidence.
            In doing so, we empower them to realize their objectives, stay ahead
            of industry trends, and thrive in today's dynamic digital landscape.
          </p>
        </div>
        <img src={app5} style={{ width: "30%" }} />
      </div>
    </div>
  );
}
