import React from "react";
import "../styles/Footer.css";
import logo from "../images/logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";

export default function Footer() {
  return (
    <div>
      <footer>
        <div class="row">
          <div class="column">
            <a style={StyledAnchor} href="/">
              <img
                src={logo}
                className="img-fluid  "
                // className="p-5"
                alt="logo"
                style={{
                  width: "250px",
                  // height: "auto",
                  // paddingBottom: "20px",
                  marginLeft: "70px",
                  marginTop: "-10%",
                }}
              />
            </a>
            {/* <div style={{ marginTop: "-8%", marginLeft: "30%" }}>
              <h5>Address:</h5>
              <span>
                {" "}
                B-1805, Dosti Oak, Dosti West County, Balkum Road,
                Thane West, MH-400608
              </span>
            </div> */}
          </div>

          <div class="column">
            <h4>Quick Links</h4>

            <ul>
              <li>
                <a href="/">
                  <i class="fa fa-angle-right"></i> Home
                </a>
              </li>
              <li>
                <a href="about">
                  <i class="fa fa-angle-right"></i> About us
                </a>
              </li>

              <li>
                <a href="contact">
                  <i class="fa fa-angle-right"></i> Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div class="column">
            <h4>Services Links</h4>

            <ul>
              <li>
                <a href="cloudConsultation">
                  <i class="fa fa-angle-right"></i> Cloud Consultation
                </a>
              </li>
              <li>
                <a href="cloudMigration">
                  <i class="fa fa-angle-right"></i> Cloud Migration
                </a>
              </li>

              <li>
                <a href="costOptimization">
                  <i class="fa fa-angle-right"></i> Cost Optimization
                </a>
              </li>
              <li>
                <a href="wellArchitected">
                  <i class="fa fa-angle-right"></i> Well-Architected Reviews
                </a>
              </li>
              <li>
                <a href="uiux">
                  <i class="fa fa-angle-right"></i> UI/UX Design
                </a>
              </li>
              <li>
                <a href="web">
                  <i class="fa fa-angle-right"></i> Web Developement
                </a>
              </li>
            </ul>
          </div>

          <div class="column">
            <h4>Connect with Us</h4>
            <i className="fa fa-phone" aria-hidden="true"></i>{" "}
            <span
              style={{
                padding: "10px",
              }}
            >
              +91-8260226919
            </span>
            <br></br>
            <i className="fas fa-envelope" aria-hidden="true"></i>
            <span
              style={{
                padding: "10px",
                paddingBottom: "10px",
              }}
            >
              {" "}
              sales@bhashasoftware.com
            </span>
            <ul
              class="social-icons"
              style={{
                marginTop: "2%",
              }}
            >
              <li>
                <a href="#">
                  <i class="fa-brands fa-facebook-f"></i>
                </a>
              </li>

              <li>
                <a href="#">
                  <i class="fa-brands fa-instagram"></i>
                </a>
              </li>

              <li>
                <a href="#">
                  <i class="fa-brands fa-twitter"></i>
                </a>
              </li>

              <li>
                <a href="#">
                  <i class="fa-brands fa-github"></i>
                </a>
              </li>
            </ul>
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.2579660123974!2d72.99098607520915!3d19.227585982008474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bfa2eba605e5%3A0xd4b375147f568f59!2sDosti%20Oak!5e0!3m2!1sen!2sin!4v1713262876759!5m2!1sen!2sin"
              width="250"
              height="150"
              // style="border:0;"
              style={{ border: 1, marginTop: "5%", borderRadius: "10px" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe> */}
          </div>
        </div>

        <p class="copyright">
          © {new Date().getFullYear()} All Rights Reserved
        </p>
      </footer>
    </div>
  );
}
const StyledAnchor = {
  textDecoration: "none",
};
